import React, {useEffect, useState} from 'react'
import H2 from './headings/H2'
import Subtitle from './headings/Subtitle'
import Client from 'shopify-buy'

// const shopifyClient = Client.buildClient({
//   domain: 'whifffresheners.myshopify.com', //`${process.env.SHOPIFY_STORE}.myshopify.com`,
//   storefrontAccessToken: '8d8be545cfd02316b7b9480d71eb14ed', //process.env.SHOPIFY_STOREFRONT_TOKEN,
// })
// console.log('shopi', shopifyClient)

export const Address = ({address, title, subtitle}) => {
  // const [checkout, setCheckout] =
  //   useState<ShopifyBuy.Cart | null>(null)

  //   console.log(checkout,"checkout")

  // async function createShopifyCheckout() {
  //   const checkout = await shopifyClient.checkout
  //     .fetch()
  //   setCheckout(checkout)
  // }

  // useEffect(() => {
  //   createShopifyCheckout()
  // }, [])

  return (
    <>
      <div className="address-box-outer">
        <p className="address-title">{title}</p>
        {/* <Subtitle>{subtitle}</Subtitle> */}
        <div
          tw="mt-3 text-gray-400 "
          className="address-box-inner"
        >
          <p>
            {address?.first_name} {address?.last_name}
          </p>
          <p tw="mt-1">{address?.address1}</p>
          <p tw="mt-1">
            {!!address?.address2 && address?.address2}
          </p>
          <p tw="mt-1">
            {address?.city}, {address?.state}{' '}
            {address?.postal_code}
          </p>
        </div>
      </div>
    </>
  )
}
