import {useCallback, useEffect, useState} from 'react'
import {
  initialState,
  lookupStorageKey,
  offset,
} from '../apollo/cache'
import {useSessionStorageState} from '../utils/useSessionStorageState'
import {OrderByIdQuery} from '../apollo/queries'
import {useLazyQuery} from '@apollo/client'
import {useRouter} from 'next/router'

const useOrderRefresher = () => {
  const router = useRouter()
  const [state, setState] = useSessionStorageState(
    lookupStorageKey,
    initialState,
  )
  const [refreshOrder, setRefreshOrder] = useState(false)
  const [completed, setCompleted] = useState(false)

  const [fetchOrder, {loading, networkStatus}] = useLazyQuery(
    OrderByIdQuery,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      ssr: false,
      onCompleted(data) {
        setState({
          ...state,
          order: data.getOrderById,
          timestamp: Date.now(),
        })
        setCompleted(true)
        setRefreshOrder(false)
        // router.push(`/order/${state.orderNumber}`)
      },
      onError(error) {
        console.log('Error? ', error)
      },
    },
  )

  useEffect(() => {
    setCompleted(false)
    setRefreshOrder(true)
  }, [router.asPath])

  useEffect(() => {
    setCompleted(false)
    if (!state.timestamp) return null

    // check if our timestamp is more than 10s old
    let timer = setInterval(() => {
      Date.now() > state.timestamp + offset
        ? setRefreshOrder(true)
        : setRefreshOrder(false)
    }, 10000) // 10 seconds

    return () => {
      clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!refreshOrder || !state?.order?.id) return
    fetchOrder({
      variables: {id: state.order.id, email: state.email},
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshOrder])

  return {
    refreshOrder,
    setRefreshOrder,
    completed,
    networkStatus,
  }
}

export default useOrderRefresher
