// import {DocumentNode, gql, useMutation} from '@apollo/client'
// import {useState} from 'react'
// import {ScissorsIcon} from '@heroicons/react/outline'
// import Button from './Button'
// import {useSessionStorageState} from '../utils/useSessionStorageState'

import {useEffect, useState} from 'react'
import Client from 'shopify-buy'

const shopifyClient = Client.buildClient({
  domain: 'whifffresheners.myshopify.com', //`${process.env.SHOPIFY_STORE}.myshopify.com`,
  storefrontAccessToken: '8d8be545cfd02316b7b9480d71eb14ed', //process.env.SHOPIFY_STOREFRONT_TOKEN,
})
// console.log('shopi', shopifyClient)

// const UPDATE_CROP = gql`
//   mutation UpdateCrop($crop: String!, $orderId: String!, $itemId: Int!) {
//     updateCrop(crop: $crop, orderId: $orderId, itemId: $itemId) {
//       status
//       message
//       execution_time
//     }
//   }
// `
type Props = {
  currentCrop: string
  setNewCrop?: (crop: string) => void
  type?: string
}

const CropAction: React.FC<Props> = ({
  currentCrop,
  setNewCrop,
  type = 'New',
}) => {
  const [newCrop, setnewCrop] = useState([])
  // console.log(newCrop)
  const filteredCrop = newCrop.filter(
    sc => sc.value != 'Select A Crop',
  )

  async function buyIt() {
    // setLoading(true)
    // setStatus('pending')
    // console.log('shopifyClient.product', shopifyClient.product)
    return await shopifyClient.product
      .fetchAll()
      .then(products => {
        // console.log('shopifyClient', shopifyClient)

        // console.log('products', products)
        return products.find(item => {
          item.title === 'Custom Air Freshener',
            setnewCrop(item.options[1].values)
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    buyIt()
  }, [])

  return (
    <div tw="mt-4">
      {/* <h3 tw="text-lg font-semibold text-brand">{type} Crop</h3> */}
      <h3 tw="text-lg text-brand">Crop</h3>
      <div tw="flex-shrink-0 font-normal text-gray-500">
        <div className="select-box">
          <span className="select-icon">
            <i className="fa fa-caret-down"></i>
          </span>
          <select
            value={
              currentCrop != 'Just The Head(s)' &&
              currentCrop != 'Entire Body(s)' &&
              currentCrop != 'Do Not Crop' &&
              currentCrop != 'Just The Car' &&
              currentCrop != ''
                ? ''
                : currentCrop
            }
            onChange={e => setNewCrop(e.currentTarget.value)}
            tw="h-full w-full p-2 border border-gray-400 bg-white text-gray-500 focus:ring-brand rounded-md"
          >
            <option disabled>Select A Crop</option>
            {/* {filteredCrop.map(el => {
            // console.log(el)
            return (
              <>
                <option key={el.value} value={el.value}>
                  {el.value}
                </option>
              </>
            )
          })} */}              
            <option value="Just The Head(s)">
              Just The Head(s)
            </option>
            <option value="Entire Body(s)">
              Entire Body(s)
            </option>            
            <option value="Just The Car">Just The Car</option>
            <option value="Do Not Crop">
              Do Not Crop (we&apos;ll keep the photo as is)
            </option>
            <option value="">Other</option>
          </select>
        </div>
      </div>
    </div>
  )
}

export default CropAction
