// import styled from '@emotion/styled'
import React, {useCallback, useEffect, useState} from 'react'
import tw, {styled} from 'twin.macro'
import {lookupStorageKey} from '../../../apollo/cache'
import {getLayout} from '../../../components/OrderLayout'
import {useSessionStorageState} from '../../../utils/useSessionStorageState'
import H2 from '../../../components/headings/H2'
// import {OrderItem} from '../../../components/OrderItem'
import {CheckIcon} from '@heroicons/react/outline'
// import Link from 'next/link'
// import {useRouter} from 'next/dist/client/router'
import {actions} from '../../../components/OrderActions'
import UnavailableAction from '../../../components/UnavailableAction'
import Subtitle from '../../../components/headings/Subtitle'
import ShadowBox from '../../../components/layout/ShadowBox'
import {UpdateItem, UploadItemPhoto} from './update-items'
import Button from '../../../components/Button'
import {Loading} from '../../../components/Loading'
import useDeliveryStatus from '../../../hooks/useDeliveryStatus'
import {OrderItem} from '../../../components/OrderItem'
import {gql, useMutation} from '@apollo/client'
import router from 'next/router'
import {ORDER_FIELDS} from '../../../apollo/fragments'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const IncDinc = styled.button([
  tw`relative inline-flex items-center px-2 py-1 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 disabled:opacity-80 disabled:cursor-not-allowed disabled:ring-0`,
])

const solutions = [
  {
    title: 'Free Replacement',
    subtitle: "We'll remake and reship your items to you.",
    id: 'free-replacement',
  },
  {
    title: '20% Refund',
    subtitle:
      "We'll issue a 50% refund for the eligible items you select.",
    id: '20%-refund',
  },
  {
    title: '100% Refund',
    subtitle:
      "We'll issue a 50% refund for the eligible items you select.",
    id: '100%-refund',
  },
]

const reasons = [
  {
    title: 'Scent',
    subtitle: 'You are not satisfied with your scent',
    id: 'scent',
  },
  {
    title: 'Photo',
    subtitle: 'You are not satisfied with your order',
    id: 'photo',
  },
  {
    title: 'Damaged',
    subtitle: 'Your order arrived damaged',
    id: 'damaged',
  },
  {
    title: 'Incorrect',
    subtitle: 'You received the wrong order',
    id: 'incorrect',
  },
  {
    title: 'Moisture',
    subtitle: 'Your order arrived too wet',
    id: 'moisture',
  },
  {
    title: 'Missing Item(s)',
    subtitle: 'Your order was not delivered',
    id: 'missing',
  },
  {
    title: 'Delivery Issue',
    subtitle: 'Your order was not delivered',
    id: 'delivery',
  },
]

const initialState = {
  solution: '',
  reason: '',
  image: null,
  items: [], // {id, scent, crop, customCrop, quantity, image}
}

// const UPDATE_ORDER_ITEMS = gql`
//   ${ORDER_FIELDS}
//   mutation UpdateOrderItems(
//     $orderId: String!
//     $items: [UpdateItem!]!
//   ) {
//     updateOrderItems(orderId: $orderId, items: $items) {
//       status
//       message
//       execution_time
//       order {
//         ...OrderFields
//       }
//     }
//   }
// `

export const REFUND_ORDER = gql`
  ${ORDER_FIELDS}
  mutation RefundOrder(
    $orderId: String!
    $folderId: Int!
    $items: [UpdateItem!]!
    $orderMeta: [OrderMetaInput]!
  ) {
    refundOrder(
      orderId: $orderId
      folderId: $folderId
      items: $items
      orderMeta: $orderMeta
    ) {
      status
      message
      order {
        ...OrderFields
      }
    }
  }
`

const Refund = ({delStatus, setrefundStatus}) => {
  // const router = useRouter()
  const [state, setState] =
    useSessionStorageState(lookupStorageKey)
  const [shQuantity, setshQuantity] = useState()
  const [itemsToUpdate, setItemsToUpdate] = useState([])
  const [orderItems, setOrderItems] = useState()
  const [replacementError, setReplacementError] = useState('')
  const [first, setFirst] = useState()
  // console.log(first, 'first')
  const [refundState, setRefundState] = useState(initialState)
  const [checkSet, setCheckSet] = useState(false)
  // const [state] = useSessionStorageState(lookupStorageKey)
  const [status, setStatus] = useState('idle') // idle, pending, resolved, rejected
  const [overrideReturnable, setOverrideReturnable] =
    useState(false)
  const {order} = state
  // console.log(order, 'order')
  const {checkDeliveryStatus, returnable} = useDeliveryStatus(
    order?.id,
  )
  const [PreferredReturn, setPreferredReturn] = useState('')
  const [trueState, setTureState] = useState(0)
  const [newQuantites, setnewQuantites] = useState(null)
  const [newQuantitesId, setnewQuantitesId] = useState(null)
  const [orderUpdateItems, setOrderUpdateItems] = useState(
    order?.order_items,
  )
  // console.log(trueState, 'trueState')
  // console.log(PreferredReturn, 'PreferredReturn')
  const [refundOrder, {error, data}] = useMutation(
    REFUND_ORDER,
    {
      onCompleted: data => {
        console.log(data, 'datafromrefund')
        const {
          refundOrder: {status},
        } = data
        // console.log('On COmpleted Data: ', data)
        if (status === 'success') {
          setStatus('resolved')
          setState({
            ...state,
            order: {...state.order, folder_id: 435734},
            timestamp: Date.now(),
          })
          setrefundStatus(true)
          router.push(
            `/order/${state.orderNumber}/success?source=refund_request`,
          )
        } else {
          setStatus('rejected')
        }
      },
      onError: error => {
        console.log('ERROR: ', error)
        setStatus('rejected')
      },
    },
  )

  useEffect(() => {
    if (!order_shipments) return
    checkDeliveryStatus(order_shipments[0], order_metadata)
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addItemToReturn = useCallback((itemId, details) => {
    setRefundState(prev => {
      // 1. Is the item already added in the array?
      const isItemInItems = prev.items.find(
        item => +item.id === +itemId,
      )
      if (isItemInItems) {
        const items = prev.items.map(item =>
          +item.id === +itemId ? {...item, ...details} : item,
        )
        return {...prev, items: [...items]}
      }
      // return prev
      return {
        ...prev,
        items: [...prev.items, {id: +itemId, ...details}],
      }
    })
  }, [])

  const removeItemFromReturn = useCallback(itemId => {
    setRefundState(prev => {
      return {
        ...prev,
        items: prev.items.reduce((ack, item) => {
          if (+item.id === +itemId) {
            return ack
          } else {
            return [...ack, item]
          }
        }, []),
      }
    })
  }, [])

  function handlePhotoChange(url) {
    setRefundState({
      ...refundState,
      image: url,
    })
  }

  function handleSubmit() {
    toastr.options = {
      positionClass: 'toast-top-right',
      hideDuration: 300,
      timeOut: 3000,
    }

    toastr.success(`Submitted`)

    if (!first && refundState.solution === 'free-replacement') {
      setReplacementError('Please select a scent.')
      return
    }
    setStatus('pending')
    let orderMeta = [
      {key: 'CutInHalfImage', value: refundState.image},
      {key: 'Reason', value: refundState.reason},
      {key: 'Solution', value: refundState.solution},
    ]
    // console.log(orderMeta)

    refundOrder({
      variables: {
        orderId: order.id,
        items: refundState.items,
        folderId: 435734,
        orderMeta,
      },
    })
  }

  const check = ['moisture', 'missing']

  const checkone = ['delivery', 'missing']
  // console.log(delStatus, 'delStatusinrefund')

  const formIsValid = () => {
    if (!refundState.solution || !refundState.reason)
      return false
    if (refundState.items.length === 0) return false
    if (
      refundState.reason === 'delivery' ||
      refundState.reason === 'missing'
    )
      return true
    if (refundState.reason !== 'delivery' && !refundState.image)
      return false

    return true
  }

  // const [mutate] = useMutation(UPDATE_ORDER_ITEMS, {
  //   onCompleted: data => {
  //     if (data.updateOrderItems.status === 'success') {
  //       setStatus('resolved')
  //       setItemsToUpdate([])
  //       data.updateOrderItems.order &&
  //         setState({
  //           ...state,
  //           order: data.updateOrderItems.order,
  //           timestamp: Date.now(),
  //         })
  //       router.push(
  //         `/order/${state.orderNumber}/success?source=updated_items`,
  //       )
  //     } else {
  //       setStatus('rejected')
  //     }
  //   },
  // })

  useEffect(() => {
    if (!order || !order?.order_metadata) return
    const extended = order?.order_metadata?.find(
      v => v.key === 'ExtendRequestUntil',
    )?.value
    if (!extended) {
      setOverrideReturnable(false)
      return
    }

    const isReturnable =
      new Date(extended.replaceAll('-', '/')).getTime() -
      new Date().getTime()

    setOverrideReturnable(isReturnable > 0)
  }, [order?.order_metadata])

  if (!state.order) return null
  const {
    order_shipments,
    order_metadata,
    folder_id,
    order_items,
  } = order
  // console.log(order, 'order')

  const updateOrderItem = (item, options) => {
    const tempItems = orderUpdateItems
    tempItems.map(i => {
      if (i.id == item.id) {
        let isSet = false
        i.metadata.map(v => {
          if (v.key == 'image') {
            v.value = options.image
          }
        })

        i.variation_list.map(v => {
          if (v.key == 'Crop') {
            v.value = options.crop
          }

          if (v.key == 'Scent') {
            v.value = options.scent
          }

          if (v.key == 'customCrop') {
            v.value = options.customCrop
            isSet = true
          }
        })

        if (!isSet) {
          let item = {
            __typename: 'OrderMeta',
            key: 'customCrop',
            value: options.customCrop,
          }

          i.variation_list.push(item)
        }
      }
    })

    setOrderUpdateItems(tempItems)
  }

  // const filteredOrder = newScent.filter(
  //   sc => sc.value != 'Select A Scent',
  // )

  const currentAction = actions.find(
    action => action.id === 'refund',
  )
  // if (!overrideReturnable) {
  //   if (
  //     !currentAction ||
  //     !currentAction.folders.includes(folder_id) ||
  //     !returnable
  //   )
  //     return <UnavailableAction />
  // }

  let ExtendRequestUntil = order.order_metadata?.find(
    v => v.key === 'ExtendRequestUntil',
  )?.value
  // console.log(ExtendRequestUntil, 'ExtendRequestUntil')

  let deliverystatus = order.order_metadata?.find(
    v => v.key === 'delivery_status',
  )?.value
  if (deliverystatus) {
    deliverystatus = deliverystatus && JSON.parse(deliverystatus)
  }
  // console.log('deliverystatus', deliverystatus)

  let Request = order?.order_items?.find(
    v => v.name === 'Request',
  )
  // console.log(Request, 'Request')

  return (
    <>
      <div className="item-box">
        <div className="row pt-3">
          <div className="col-md-6">
            {/* <ShadowBox>
            <H2>Reason</H2>
            <Subtitle>
              What is the main issue with your order?
            </Subtitle> */}
            <p className="text-brand">Reason For Request</p>
            <div className="select-box">
              <span className="select-icon">
                <i className="fa fa-caret-down"></i>
              </span>
              <select
                onChange={e =>
                  setRefundState({
                    ...refundState,
                    reason: e.target.value,
                  })
                }
                tw="h-full w-full p-2 border border-gray-400 bg-white text-gray-500 focus:ring-brand rounded-md"
              >
                <option hidden>Please Make A Selection</option>
                {reasons.map(reason => (
                  <option
                    key={reason.id}
                    value={reason.id}
                    css={[
                      tw`block w-full hover:bg-gray-100 rounded-md`,
                      // reason.id === 'delivery' &&
                      //   !returnable &&
                      //   tw`hidden`,
                    ]}
                  >
                    {/* <div tw="p-2 sm:p-4 flex items-center sm:px-6">
                <span
                  tw="w-4 h-4 bg-transparent border border-brand rounded-full relative inline-flex items-center justify-center"
                  style={{minWidth: '16px'}}
                >
                  {refundState.reason === reason.id && (
                    <span tw="rounded-full h-2 w-2 bg-brand" />
                  )}
                </span>
                <div tw="ml-4 sm:ml-8 text-left">
                  <h3 tw="text-brand text-base font-semibold leading-none md:leading-normal">
                    {reason.title}
                  </h3>
                  <Subtitle>{reason.subtitle}</Subtitle>
                </div>
              </div> */}
                    {reason.title}
                  </option>
                ))}
              </select>
            </div>
            {/* </ShadowBox> */}
          </div>
          <div className="col-md-6">
            {/* <ShadowBox>
            <H2>Solution</H2>
            <Subtitle>How can we make it right?</Subtitle> */}
            <p className="text-brand">
              How Can We Make This Right?
            </p>
            <div className="select-box">
              <span className="select-icon">
                <i className="fa fa-caret-down"></i>
              </span>
              <select
                onChange={e =>
                  setRefundState({
                    ...refundState,
                    solution: e.target.value,
                  })
                }
                tw="h-full w-full p-2 border border-gray-400 bg-white text-gray-500 focus:ring-brand rounded-md"
              >
                <option hidden>Please Make A Selection</option>
                {solutions
                  .filter(e => {
                    if (
                      refundState.reason === 'scent' ||
                      refundState.reason === 'photo' ||
                      refundState.reason === 'damaged' ||
                      refundState.reason === 'incorrect'
                    ) {
                      if (
                        order?.order_total === 0 ||
                        ExtendRequestUntil
                      ) {
                        return (
                          e.id === '20%-refund' ||
                          e.id === 'free-replacement'
                        )
                      } else if (
                        order?.order_total > 0 ||
                        !ExtendRequestUntil
                      ) {
                        return (
                          e.id === '100%-refund' ||
                          e.id === 'free-replacement'
                        )
                      }
                    } else if (
                      refundState.reason === 'missing' ||
                      refundState.reason === 'moisture'
                    ) {
                      return (
                        e.id === '20%-refund' ||
                        e.id === 'free-replacement'
                      )
                    } else if (
                      refundState.reason === 'delivery'
                    ) {
                      if (delStatus?.status === 'delivered') {
                        return (
                          e.id === '20%-refund' ||
                          e.id === 'free-replacement'
                        )
                      } else if (
                        delStatus?.status !== 'delivered'
                        // &&
                        // !ExtendRequestUntil
                      ) {
                        return (
                          e.id === '100%-refund' ||
                          e.id === 'free-replacement'
                        )
                      }
                    }

                    // if (
                    //   (refundState.reason === 'delivery' &&
                    //     deliverystatus?.status !==
                    //       'delivered') ||
                    //   order?.order_total > 0 ||
                    //   !ExtendRequestUntil ||
                    //   !check.includes(refundState?.reason)
                    // ) {
                    //   return (
                    //     e.id === '100%-refund' ||
                    //     e.id === 'free-replacement'
                    //   )
                    // } else if (
                    //   (refundState?.reason === 'delivery' &&
                    //     deliverystatus?.status !==
                    //       'delivered') ||
                    //   ExtendRequestUntil ||
                    //   order?.order_total === 0 ||
                    //   refundState.reason === 'missing' ||
                    //   refundState.reason === 'moisture'
                    // ) {
                    //   return (
                    //     e.id === '20%-refund' ||
                    //     e.id === 'free-replacement'
                    //   )
                    // } else return true
                  })
                  .map(solution => (
                    <option
                      key={solution.id}
                      value={solution.id}
                      css={[
                        tw`block w-full hover:bg-gray-100 rounded-md `,
                        // refundState.solution === solution.id && tw`bg-gray-100 ring-2`,
                      ]}
                    >
                      {/* <div tw="p-2 sm:p-4 flex items-center sm:px-6">
                <span
                  tw="w-4 h-4 bg-transparent border border-brand rounded-full relative inline-flex items-center justify-center"
                  style={{minWidth: '16px'}}
                >
                  {refundState.solution === solution.id && (
                    <span tw="rounded-full h-2 w-2 bg-brand" />
                  )}
                </span>
                <div tw="ml-4 sm:ml-8 text-left">
                  <h3 tw="text-brand text-base font-semibold leading-none md:leading-normal">
                    {solution.title}
                  </h3>
                  <Subtitle>{solution.subtitle}</Subtitle>
                </div>
              </div> */}
                      {solution.title}
                    </option>
                  ))}
              </select>
            </div>
            {/* </ShadowBox> */}
          </div>
          <div className="col-md-12 pt-2">
            {/* <ShadowBox>
              <H2>Items</H2>
              <Subtitle>
                Select the item(s) effected by the issue above.
              </Subtitle> */}

            <div tw="mt-4">
              {/* <div className="banner-text">
                  1/1 Selected For Replacement
                </div> */}

              {refundState.solution === 'free-replacement' ||
              !refundState.solution ? (
                <>
                  {orderUpdateItems.map(item => {
                    const rr = item.variation_list.find(
                      v => v.key === 'Replace-Refund',
                    )
                    if (rr && rr.value === 'True') return null
                    return (
                      // <div
                      //   className={`
                      // ${
                      //   trueState > 0 && 'item-outer border-blue'
                      // }
                      // ${
                      //   trueState === 0 &&
                      //   'item-outer border-gray'
                      // }
                      // `}
                      //   key={item.id}
                      // >
                      <UpdateItem
                        setCheckSet={setCheckSet}
                        trueState={trueState}
                        setTureState={setTureState}
                        refundState={refundState}
                        replacementError={replacementError}
                        setFirst={setFirst}
                        key={item.id}
                        item={item}
                        updater={addItemToReturn}
                        remover={removeItemFromReturn}
                        orderId={order.id}
                        type="Replacement"
                        quantity={0}
                        showQuantity={true}
                        mutate={refundOrder}
                        itemsToUpdate={refundState.items}
                        order={order}
                        states={undefined}
                        updateOrderItem={updateOrderItem}
                      />
                      // </div>
                    )
                  })}
                </>
              ) : (
                <>
                  {order_items.map(item => {
                    const stateItem = refundState.items.find(
                      i => +i.id === +item.id,
                    )
                    console.log(stateItem?.quantity, 'stateItem')
                    const rr = item.variation_list.find(
                      v => v.key === 'Replace-Refund',
                    )
                    if (rr && rr.value === 'True') return null
                    return (
                      <div
                        className={`${
                          !stateItem?.quantity &&
                          'item-outer border-gray option-box'
                        }
                        ${
                          stateItem?.quantity > 0 &&
                          'item-outer border-blue option-box'
                        }
                        `}
                        key={item.id}
                      >
                        <div
                          className="edit-items-box edit-items-box-inner"
                          key={item.id}
                          css={[
                            tw`mt-2 -mx-2 p-2 flex flex-wrap relative`,
                            tw``,
                          ]}
                        >
                          <div
                            className={`${
                              !stateItem?.quantity &&
                              'banner-text colorGray'
                            }
                        ${
                          stateItem?.quantity > 0 &&
                          'banner-text colorBlue'
                        }`}
                          >
                            {`${stateItem?.quantity || 0}/${
                              item.quantity
                            } selected`}
                          </div>
                          <OrderItem
                            shQuantity={shQuantity}
                            key={item.id}
                            item={item}
                            hasPrice={false}
                            showQuantity={true}
                            setOrderItems={setOrderItems}
                            // order_items={order_items}
                            states={state}
                          >
                            <span
                              className="refund-qty"
                              tw="absolute top-0 right-0 flex-wrap place-self-start sm:relative justify-center"
                            >
                              {/* <h3 tw=" text-base text-brand font-semibold md:truncate max-w-lg leading-none md:leading-normal">
                                Refund Qty
                              </h3> */}
                              <div className="qty-box">
                                <IncDinc
                                  type="button"
                                  css={[tw`rounded-l-md`]}
                                  disabled={
                                    stateItem?.quantity === 0
                                  }
                                  onClick={e => {
                                    stateItem?.quantity > 1
                                      ? addItemToReturn(
                                          item.id,
                                          {
                                            quantity:
                                              stateItem?.quantity -
                                                1 || 0,
                                            // RequestedQuantity:
                                            //   stateItem?.quantity - 1 || 0,
                                          },
                                        )
                                      : removeItemFromReturn(
                                          item.id,
                                        )
                                  }}
                                >
                                  -
                                </IncDinc>
                                <input
                                  className="qty-input"
                                  tw="-ml-px w-8 relative inline-flex items-center px-2 py-1 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                                  value={
                                    stateItem?.quantity || 0
                                  }
                                  onChange={e => {
                                    if (
                                      Number(e.target.value) <=
                                      item.quantity
                                    ) {
                                      addItemToReturn(item.id, {
                                        quantity: Number(
                                          e.target.value,
                                        ),
                                      })
                                    }
                                  }}
                                />
                                {/* <span
                                className="qty-input"
                                tw="-ml-px w-8 relative inline-flex items-center px-2 py-1 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                              >
                                {stateItem?.quantity || 0}
                              </span> */}
                                <IncDinc
                                  type="button"
                                  css={[tw`rounded-r-md -ml-px`]}
                                  disabled={
                                    stateItem?.quantity ===
                                    item.quantity
                                  }
                                  onClick={e =>
                                    addItemToReturn(item.id, {
                                      quantity:
                                        stateItem?.quantity +
                                          1 || 1,
                                      // RequestedQuantity:
                                      //   stateItem?.quantity - 1 || 0,
                                    })
                                  }
                                >
                                  +
                                </IncDinc>
                              </div>
                            </span>
                          </OrderItem>
                        </div>
                      </div>
                    )
                  })}
                </>
              )}
            </div>
            {/* </ShadowBox> */}
          </div>
          <div className="col-md-12 pd-top-box">
            {refundState.reason !== 'delivery' &&
              refundState.reason != 'missing' && (
                <>
                  <div>
                    <div
                      className="gap-btween gaf-box"
                      css={[
                        tw`mt-4 p-2 lg:grid lg:grid-cols-2 gap-16 w-full`,
                      ]}
                    >
                      {refundState.reason === 'moisture' ||
                      refundState.reason === 'incorrect' ||
                      refundState.reason === 'damaged' ||
                      refundState.reason === 'scent' ||
                      refundState.reason === 'photo' ||
                      refundState.reason === '' ? (
                        <>
                          <div>
                            <p className="text-brand">
                              Preferred Return Method
                            </p>

                            <div className="select-box mb-4">
                              <span className="select-icon">
                                <i className="fa fa-caret-down"></i>
                              </span>
                              <select
                                name="cars"
                                id="cars"
                                onChange={e =>
                                  setPreferredReturn(
                                    e.target.value,
                                  )
                                }
                                tw="h-full w-full p-2 border border-gray-400 bg-white text-gray-500 focus:ring-brand rounded-md"
                              >
                                <option hidden>
                                  Please Make A Selection
                                </option>
                                <option value={'cutitems'}>
                                  Cut item(s) in half and upload
                                  photo
                                </option>
                              </select>
                            </div>

                            {PreferredReturn === 'cutitems' ? (
                              <div className="crop-photo-box">
                                <UploadItemPhoto
                                  state={state}
                                  orderId={order.id}
                                  itemId={`${+order.id}`}
                                  updatePhoto={handlePhotoChange}
                                  type={null}
                                  defaultImage={null}
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <div>
                            {PreferredReturn == 'cutitems' ? (
                              <div tw="mt-4 lg:mt-0">
                                <h3 tw="text-base text-brand font-semibold">
                                  How?
                                </h3>
                                <Subtitle tw="mt-1">
                                  Please cut your item(s) in
                                  half, or if you are
                                  uncomfortable doing so, please
                                  cut out the string hole in a
                                  &apos;V&apos; shape.
                                </Subtitle>
                                <h3 tw="mt-5 text-base text-brand font-semibold">
                                  Why?
                                </h3>
                                <Subtitle tw="mt-1">
                                  Since all items are made to
                                  order, rather than requesting
                                  you to return your item(s) to
                                  us, we require a photo of your
                                  item(s) in unusable condition.
                                  We do this in order to make
                                  your return/replacement process
                                  as convenient as possible and
                                  to do our part to save the
                                  planet.
                                </Subtitle>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </>
                      ) : (
                        ''
                      )}

                      {/* {PreferredReturn === 'cutitems' && (
                      <div className="crop-photo-box">
                        <UploadItemPhoto
                          orderId={order.id}
                          itemId={`${+order.id}`}
                          updatePhoto={handlePhotoChange}
                          type={null}
                          defaultImage={null}
                        />
                      </div>
                    )} */}
                    </div>

                    {/* {PreferredReturn == 'cutitems' && (
                    <div tw="mt-4 lg:mt-0">
                      <h3 tw="text-base text-brand font-semibold">
                        How?
                      </h3>
                      <Subtitle tw="mt-1">
                        Please cut your item(s) in half, or if
                        you are uncomfortable doing so, please
                        cut out the string hole in a
                        &apos;V&apos; shape.
                      </Subtitle>
                      <h3 tw="mt-5 text-base text-brand font-semibold">
                        Why?
                      </h3>
                      <Subtitle tw="mt-1">
                        Since all items are made to order, rather
                        than requesting you to return your
                        item(s) to us, we require a photo of your
                        item(s) in unusable condition. We do this
                        in order to make your return/replacement
                        process as convenient as possible and to
                        do our part to save the planet.
                      </Subtitle>
                    </div>
                  )} */}
                  </div>
                  {/* </ShadowBox> */}
                </>
              )}

            <div tw="mx-auto text-center mt-4">
              <button
                className="brand-btn"
                type="submit"
                css={[status === 'resolved' && tw`bg-green-600`]}
                onClick={handleSubmit}
                disabled={
                  status === 'pending' || !formIsValid()
                  //  || !checkSet
                }
              >
                {status === 'resolved' ? 'Sent' : 'Submit'}
                {status === 'pending' ? (
                  <Loading tw="ml-2" />
                ) : null}
                {status === 'resolved' ? (
                  <CheckIcon tw="pl-2 h-5 w-5" />
                ) : null}
              </button>
              {status === 'resolved' && (
                <p tw="max-w-xs text-green-600 mx-auto text-xs mt-2">
                  Please check your email for a replacement
                  request confirmation as well as updates
                  regarding your request.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Refund.getLayout = getLayout
Refund.displayName = 'Refund'

export default Refund
