import Image from 'next/image'
import useOrderItem from '../hooks/useOrderItem'
// import {Fragment} from 'react'
import {Price} from './Price'
import {XIcon} from '@heroicons/react/outline'
import tw from 'twin.macro'
import {useEffect, useRef, useState} from 'react'
import {isMobile} from 'react-device-detect'

interface OrderItemProps {
  shQuantity: any
  states: any
  setOrderItems: any
  // order_items: any
  item: any
  hasPrice: boolean
  showQuantity?: boolean
  clickHandler?: (evt: any) => void
  // css?: TwStyle[]
}

export const OrderItem: React.FC<OrderItemProps> = ({
  states,
  shQuantity,
  setOrderItems,
  // order_items,
  item,
  hasPrice,
  showQuantity = true,
  children,
  clickHandler = null,
}) => {  
  const {image, scent, crop, flag} = useOrderItem(item)
  const [first, setfirst] = useState(0)

  const Quantity = () => (
    <span
      className="item-qty"
      tw="mx-auto font-semibold md:pt-1 inline-flex self-start items-center text-brand leading-none"
    >
      {/*mr-40 */}x{item.quantity}
    </span>
  )

  useEffect(() => {
    setOrderItems(states)
  }, [states])

  return (
    <>
      <div className="d-flex-item ">
        <div
          className="img-size"
          css={[tw`mr-4 flex-shrink-0 relative mb-4 md:mb-0`]}
          style={{fontSize: 0}}
          onClick={clickHandler}
        >
          {image && (
            <img
              tw="border border-gray-300 bg-white text-gray-300 object-cover"
              src={image}
              // width={65}
              // height={95}
              // quality={50}
              alt=""
              // objectFit="cover"
            />
          )}
        </div>
        {/* <div tw="md:place-self-center" onClick={clickHandler}> */}
        <div tw="md:pt-1" onClick={clickHandler}>
          <h3
            className="order-heading"
            css={[
              tw`text-base text-brand font-semibold md:truncate max-w-lg leading-none md:leading-normal`,
              //flag && flag === 'Design' && tw`text-red-600`
            ]}
          >
            {item.name.split(' - ')[0]}{' '}
          </h3>
          <dl
            css={[tw`mt-1 text-sm text-gray-400`]}
            className="font-w-regular"
          >
            <div className="flex-item item-scent" tw="grid items-center">
              <dt tw="">Scent:</dt>
              <dd tw="ml-1 absolute md:pl-11">{scent}</dd>
            </div>
            <div className="flex-item" tw="flex items-center">
              <dt tw="">Crop:</dt>
              <dd tw="ml-1">
              {
                crop == 'Do Not Crop'? "Do Not Crop (we'll keep the photo as is)" : crop
                // crop != 'Just The Head(s)' && crop != 'Entire Body(s)' && crop != 'Do Not Crop' && crop != 'Just The Car'? 'Other' : crop
              }
              </dd>
            </div>
            <div className="flex-item" tw="flex items-center">
              <dt tw="">Photo:</dt>
              <dd tw="ml-1">
                <a
                  tw="underline cursor-pointer hover:text-brand"
                  href={image}
                  target="_blank"
                  rel="noreferrer"
                >
                  Link
                </a>
              </dd>
            </div>
            {!shQuantity && (
              <div className="flex-item" tw="flex items-center">
                <dt tw="">Quantity:</dt>
                <dd tw="ml-1">{item.quantity}</dd>
              </div>
            )}
          </dl>
        </div>
      </div>
      {showQuantity && <Quantity />}

      {hasPrice ? (
        <span
          className="item-price"
          css={[
            tw`leading-none font-semibold place-self-start md:pt-1`,
            !showQuantity && tw`mx-auto md:-mt-0.5`,
          ]}
        >
          <Price>${item.price}</Price>
        </span>
      ) : null}

      {children}
    </>
  )
}
