import {XIcon} from '@heroicons/react/outline'
import React, {Fragment, useEffect, useState} from 'react'
import {useQuery} from '@apollo/client'
import {PortalOrderByIdQuery} from '../apollo/queries'
import tw from 'twin.macro'
import {Transition} from '@headlessui/react'

export default function Banner() {
  const [visible, setVisible] = useState(true)
  // const [isShowing, setIsShowing] = useState(false)
  const {loading, data} = useQuery(PortalOrderByIdQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {id: '220078669', email: 'jacob@jacobdubail.com'},
  })
  const [bannerItem, setBannerItem] = useState(null)

  useEffect(() => {
    const bannerItem = data?.getOrderById?.order_items.find(
      item => item.name === 'Banner',
    )
    setBannerItem(bannerItem)
    return () => {}
  }, [data])

  if (!bannerItem) return null
  const {variation_list} = bannerItem

  const enabled = variation_list?.find(
    v => v.key === 'Visible',
  )?.value
  const bannerText =
    variation_list?.find(
      v => v.key === 'Text' || v.key === 'Message',
    )?.value || 'Orders are taking about 4 weeks to ship out'
  const bgColor =
    variation_list?.find(
      v =>
        v.key === 'BackgroundColor' || v.key === 'Banner Color',
    )?.value || null
  const textColor =
    variation_list?.find(
      v => v.key === 'TextColor' || v.key === 'Text Color',
    )?.value || null

  // console.log('Banner Enabled? ', enabled)

  if (enabled !== 'true') return null

  return (
    <Transition.Root as={Fragment} show={visible}>
      <div
        css={[
          tw`sticky bg-red-600 transition-all duration-150 max-h-20 md:max-h-12 top-0 w-full z-10`,
          !visible && tw`max-h-0 opacity-0`,
        ]}
        style={{
          backgroundColor: bgColor,
          color: textColor,
        }}
      >
        <div tw="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div tw="pr-16 sm:text-center sm:px-16">
            <p tw="font-medium">{bannerText}</p>
          </div>
          <div tw="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
            <button
              type="button"
              onClick={() => setVisible(false)}
              tw="cursor-pointer flex p-2 rounded-md hover:bg-black focus:outline-none focus:ring-2 focus:ring-white"
            >
              <span tw="sr-only">Dismiss</span>
              <XIcon tw="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </Transition.Root>
  )
}
