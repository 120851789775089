import {gql} from '@apollo/client'
import {ORDER_FIELDS} from './fragments'

export const OrderQuery = gql`
  query Order($id: String!, $email: String!) {
    getOrder(id: $id, email: $email) {
      id
      source_id
      email
      shipping_method
      quantity_total
      weight_total
      product_total
      shipping_total
      handling_total
      tax_total
      discount_total
      order_total
      cc_number_masked
      cc_exp
      processor_response
      payment_type
      payment_status
      processor_balance
      refund_total
      # customer_id
      email_count
      ip_address
      tag_color
      source_name
      source_id
      folder_id
      date_added
      date_updated
      shipping {
        first_name
        last_name
        company
        address1
        address2
        city
        state
        postal_code
        country
        phone
      }
      customer {
        first_name
        last_name
        company
        address1
        address2
        city
        state
        postal_code
        country
        phone
      }
      order_shipments {
        id
        order_id
        store_id
        tracking_number
        carrier_code
        shipment_method
        weight
        cost
        status
        tracking_url
        label_format
        label_image
        order_items
        print_status
        cart_shipment_id
        date_shipped
        date_added
      }
      checkout_data {
        key
        value
      }
      order_metadata {
        key
        value
      }
      order_items {
        id
        name
        price
        quantity
        weight
        code
        delivery_type
        category_code
        variation_list {
          key
          value
        }
        metadata {
          key
          value
        }
      }
    }
  }
`

export const OrderByIdQuery = gql`
  ${ORDER_FIELDS}
  query OrderById($id: String!, $email: String!) {
    getOrderById(id: $id, email: $email) {
      ...OrderFields
    }
  }
`

export const PortalOrderByIdQuery = gql`
  query OrderById($id: String!, $email: String!) {
    getOrderById(id: $id, email: $email) {
      id
      source_id
      email
      order_items {
        id
        name
        variation_list {
          key
          value
        }
      }
    }
  }
`
export const GET_DAYS = gql`
  query GET_DAYS($id: String) {
    getDays(id: $id) {
      days {
        from
        to
        minimumShipDate
        maximumShipDate
        minimumDeliveryDate
        maximumDeliveryDate
      }
    }
  }
`