import {useMemo} from 'react'
import {ApolloClient} from '@apollo/client'
import {cache} from './cache'
import {
  persistCache,
  SessionStorageWrapper,
} from 'apollo3-cache-persist'
import isEqual from 'lodash/isEqual'
import merge from 'deepmerge'
import {createUploadLink} from 'apollo-upload-client'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient

function createIsomorphLink() {
  if (typeof window === 'undefined') {
    const {SchemaLink} = require('@apollo/client/link/schema')
    const {schema} = require('./schema')
    return new SchemaLink({schema})
  } else {
    // const {HttpLink} = require('@apollo/client/link/http')
    // const {createUploadLink} = require('apollo-upload-client')
    return new createUploadLink({
      // uri: 'http://localhost:3000',
      uri: '/api/graphql',
      credentials: 'same-origin',
    })
  }
}

;(async () => {
  if (typeof window !== 'undefined') {
    await persistCache({
      cache,
      storage: new SessionStorageWrapper(window.sessionStorage),
    })
  }
})()

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: createIsomorphLink(),
    cache,
  })
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    const existingCache = _apolloClient.extract()
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d =>
          sourceArray.every(s => !isEqual(d, s)),
        ),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] =
      client.cache.extract()
  }

  return pageProps
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(() => initializeApollo(state), [state])
  return store
}
