// import styled from '@emotion/styled'
const Label = ({id, text}) => {
  return (
    <label
      htmlFor={id}
      tw="block text-sm font-medium text-brand whitespace-nowrap text-overflow[ellipsis] overflow-hidden mb-0"
    >
      {text}
    </label>
  )
}

export default Label
