import React, {useState} from 'react'
import {getLayout} from '../../../components/OrderLayout'
import {lookupStorageKey} from '../../../apollo/cache'
import {useSessionStorageState} from '../../../utils/useSessionStorageState'
import H2 from '../../../components/headings/H2'
import Subtitle from '../../../components/headings/Subtitle'
import {Address} from '../../../components/Address'
import {OrderSummary} from '../../../components/OrderSummary'
import {OrderItem} from '../../../components/OrderItem'
import ShadowBox from '../../../components/layout/ShadowBox'
import Client from 'shopify-buy'

const initialState = {
  orderNumber: '',
  email: '',
  order: '',
}

const validReasons = ['Design']

const shopifyClient = Client.buildClient({
  domain: 'whifffresheners.myshopify.com', //`${process.env.SHOPIFY_STORE}.myshopify.com`,
  storefrontAccessToken: '8d8be545cfd02316b7b9480d71eb14ed', //process.env.SHOPIFY_STOREFRONT_TOKEN,
})

const Order = () => {
  const orderfromlocal = JSON.parse(
    localStorage.getItem('dangle-it-crm:lookup-state'),
  )
  // console.log(orderfromlocal, 'orderfromlocal')
  // console.log(
  //   orderfromlocal.order.shipping,
  //   'orderfromlocal.order.shipping',
  // )

  const [state] = useSessionStorageState(
    lookupStorageKey,
    initialState,
  )

  const [orderItems, setOrderItems] = useState(null)
  // console.log(orderItems, 'states?.order.order_items 33')

  const {order} = orderfromlocal
  // const shipping = orderfromlocal?.order?.shipping
  const {order_items, customer, shipping_method, shipping} =
    order

  // console.log(shipping, 'order_itemsoforderdetails')
  // console.log(shipping, 'shippingforderdetails')

  if (!orderfromlocal.order) return null

  return (
    <>
      {/* <ShadowBox> */}
      <div className="item-box order-item-box">
        {/* <H2>Items</H2>
          <Subtitle>Ordered items and their details.</Subtitle> */}
        {order_items.map(item => {
          // console.log(item)
          if (
            item.variation_list.find(
              v => v.key === 'Replace-Refund',
            )
          )
            return null
          return (
            <div
              className="order-summary-item repalcement-item"
              key={item.id}
              tw="mt-2 -mx-2 p-2 flex flex-wrap items-start  "
            >
              <OrderItem
                setOrderItems={setOrderItems}
                item={item}
                hasPrice={true}
                // order_items={order_items}
                states={state} 
                shQuantity={undefined}              />
            </div>
          )
        })}
      </div>
      {/* </ShadowBox> */}

      {/* <ShadowBox>
      <div className="item-box">
        <H2>Payment Summary</H2>
        <Subtitle>A breakdown of your transaction.</Subtitle>
        <OrderSummary order={order} />
      </div>
      </ShadowBox> */}

      <div
        tw="sm:grid sm:grid-cols-2 sm:gap-6 "
        className="mob-flex"
      >
        {/* <ShadowBox> */}
        <div className=" item-box border-top mt-2 address-box">
          <Address
            address={shipping}
            title="Shipping Address"
            subtitle="This is where your order will ship."
          />
        </div>
        {/* </ShadowBox> */}
        {/* <ShadowBox> */}
        <div className="item-box border-top mt-2 pl-0 mob-pl address-box ">
          <OrderSummary order={order} />
          {/* <Address
            address={customer}
            title="Billing Address"
            subtitle="This is the address associated with your payment method."
          /> */}
        </div>
        {/* </ShadowBox> */}
      </div>
      {/* <h3 tw="text-lg leading-6 font-medium text-brand">Shipping Method</h3>
      <p tw="mt-2 text-base text-gray-400">{shipping_method}</p> */}
    </>
  )
}

Order.getLayout = getLayout
Order.displayName = 'OrderDetails'
export default Order
