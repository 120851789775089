import Link from 'next/link'
import {
  CubeIcon,
  XIcon,
  SwitchHorizontalIcon,
  // ReplyIcon,
  HomeIcon,
  // FlagIcon,
  RefreshIcon,
  PencilIcon,
  TruckIcon,
  CashIcon,
} from '@heroicons/react/outline'
// import OrderAction from './OrderAction'
import useDeliveryStatus from '../hooks/useDeliveryStatus'
import {useEffect, useState} from 'react'
import {useSessionStorageState} from '../utils/useSessionStorageState'
import {initialState, lookupStorageKey} from '../apollo/cache'
import {useRouter} from 'next/router'
import tw from 'twin.macro'
import useOrderRefresher from '../hooks/useOrderRefresher'

export const actions = [
  {
    icon: TruckIcon,
    title: 'Order Status',
    id: 'order-status',
    link: '/order/[id]',
    folders: [],
    flag: [],
    flaggedFolders: [],
    active: true,
  },
  {
    icon: CubeIcon,
    title: 'Order Details',
    subtitle: 'See your order details and items',
    flag: [],
    flaggedFolders: [],
    id: 'order-details',
    link: '/order/[id]/order-details',
    folders: [],
    active: true,
  },
  {
    icon: PencilIcon,
    title: 'Update Items',
    subtitle: 'Change your photo, scent, or crop',
    flag: ['Design', 'Clipping Clarification', 'Low Resolution'],
    flaggedFolders: [399369, 435736, 435738, 435737],
    id: 'update-items',
    link: '/order/[id]/update-items',
    folders: [437224, 399370, 399341],
  },
  {
    icon: HomeIcon,
    title: 'Update Address',
    subtitle: "Change your order's shipping address",
    flag: [
      'Design',
      'Clipping Clarification',
      'Shipping',
      'Insufficient Address',
    ],
    flaggedFolders: [399369, 435736, 435738, 435737],
    id: 'update-address',
    link: '/order/[id]/update-address',
    folders: [
      399370, 399341, 399346, 399345, 399343, 399348, 433467,
      433468, 433469, 433470, 433471, 399347, 399342, 399344,
    ],
  },
  {
    icon: XIcon,
    title: 'Cancel Order',
    subtitle: 'Cancel your order and receive a refund',
    flag: [],
    flaggedFolders: [],
    id: 'cancel-order',
    link: '/order/[id]/cancel-order',
    folders: [
      399369, 435736, 435738, 435737, 437224, 399370, 399341,
      399346,
    ],
    //[399370, 399341, 399346, 399347, 399342, 399344],
  },
  {
    icon: SwitchHorizontalIcon,
    title: 'Replacement/Refund',
    subtitle: 'Submit a replacement/refund request',
    flag: [],
    flaggedFolders: [],
    id: 'refund',
    link: '/order/[id]/refund',
    folders: [435733, 435735],
  },
  {
    icon: RefreshIcon,
    title: 'Reorder',
    subtitle: 'Order items from this previous order',
    flag: [],
    flaggedFolders: [],
    id: 'reorder',
    link: '/order/[id]/reorder',
    folders: [],
    active: true,
  },
]

const OrderActions = () => {
  const router = useRouter()
  const {pathname, query} = router
  const {id} = query
  const {completed, networkStatus} = useOrderRefresher()
  const [state] = useSessionStorageState(
    lookupStorageKey,
    initialState,
  )
  // const state = JSON.parse(
  //   window.localStorage.getItem(lookupStorageKey),
  // )
  const {order} = state
  const {order_shipments, folder_id} = order
  const [orderMeta, setOrderMeta] = useState(null)
  const [overrideReturnable, setOverrideReturnable] =
    useState(false)
  const {checkDeliveryStatus, returnable} = useDeliveryStatus(
    state?.order?.id,
  )

  const flag = orderMeta?.find(
    v => v.key === 'Flagged Reason',
  )?.value

  useEffect(() => {
    if (!order_shipments || !orderMeta) return
    checkDeliveryStatus(order_shipments[0], orderMeta)
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkDeliveryStatus,
    folder_id,
    order,
    order_shipments,
    state,
    orderMeta,
  ])
  // console.log(folder_id, 'folder_id')
  useEffect(() => {
    setOrderMeta(state.order.order_metadata)
    return () => {}
  }, [completed, networkStatus, state?.order?.order_metadata])

  useEffect(() => {
    const extended = orderMeta?.find(
      v => v.key === 'ExtendRequestUntil',
    )?.value

    if (!extended) {
      setOverrideReturnable(false)
      return
    }

    const isReturnable =
      new Date(extended.replaceAll('-', '/')).getTime() -
      new Date().getTime()

    // console.log('UE: ', extended, isReturnable)

    setOverrideReturnable(isReturnable > 0)
  }, [orderMeta])

  const NavLink = ({action}) => {
    let active =
      action.id === 'refund'
        ? returnable || overrideReturnable
        : action.folders.includes(folder_id) || action?.active
    if (
      action.flag?.includes(flag) &&
      action?.flaggedFolders?.includes(folder_id)
    ) {
      active = true
    }

    const flaggedReason = orderMeta?.find(
      v => v.key === 'Flagged Reason',
    )?.value

    const link = active
      ? `${action.link.replace('[id]', `${id}`)}`
      : '#'

    return (
      <li tw="mt-px">
        <Link href={link} shallow={true}>
          <a
            css={[
              tw`flex items-center px-8 py-2 font-semibold cursor-pointer md:rounded-l-lg hover:bg-gray-50 md:pl-10 md:pr-6 text-brand`,
              link === router.asPath.replace('#', '') &&
                tw`bg-gray-50`,
              !active &&
                tw`cursor-not-allowed pointer-events-none opacity-60`,
              (flaggedReason === 'Shipping' ||
                flaggedReason === 'Insufficient Address') &&
                action.title === 'Update Address' &&
                tw`text-red-600`,
              (flaggedReason === 'Design' ||
                flaggedReason === 'Low Resolution') &&
                action.title === 'Update Items' &&
                tw`text-red-600`,
            ]}
          >
            <div tw="flex-shrink-0">
              <action.icon tw="h-4 w-4" />
            </div>
            <span tw="ml-2">{action.title}</span>
          </a>
        </Link>
      </li>
    )
  }

  const SpecialNavLink = () => {
    // const link = `${"/order/[id]/refund-ten".replace('[id]', `${id}`)}`
    const link = `/order/${id}/refund-ten`

    return (
      <li tw="mt-px">
        <Link href={link} shallow={true}>
          <a
            css={[
              tw`flex items-center px-8 py-2 font-semibold cursor-pointer md:rounded-l-lg hover:bg-gray-50 md:pl-10 md:pr-6 text-brand`,
              link === router.asPath.replace('#', '') &&
                tw`bg-gray-50`,
              // !active && tw`cursor-not-allowed pointer-events-none opacity-60`,
            ]}
          >
            <div tw="flex-shrink-0">
              <CashIcon tw="h-4 w-4" />
            </div>
            <span tw="ml-2">10% Refund</span>
          </a>
        </Link>
      </li>
    )
  }

  const refundOption = orderMeta?.find(
    v => v.key === '10% Refund Option in Portal',
  )
  return (
    <ul tw="pl-10">
      {actions.map(action => (
        <NavLink key={action.id} action={action} />
      ))}
      {typeof refundOption !== 'undefined' &&
        refundOption.value === '' && <SpecialNavLink />}
    </ul>
  )
}

export default OrderActions
