import {getLayout} from '../../../components/OrderLayout'
import {lookupStorageKey} from '../../../apollo/cache'
import {useSessionStorageState} from '../../../utils/useSessionStorageState'
import H2 from '../../../components/headings/H2'
import React, {useEffect, useState} from 'react'
import Label from '../../../components/inputs/Label'
import Text from '../../../components/inputs/Text'
import Button from '../../../components/Button'
import {gql, useMutation} from '@apollo/client'
import {Loading} from '../../../components/Loading'
import {CheckIcon} from '@heroicons/react/solid'
import {ExclamationCircleIcon} from '@heroicons/react/outline'
import {actions} from '../../../components/OrderActions'
import UnavailableAction from '../../../components/UnavailableAction'
import router from 'next/router'
import ShadowBox from '../../../components/layout/ShadowBox'
import Subtitle from '../../../components/headings/Subtitle'
import tw from 'twin.macro'
import {ORDER_FIELDS} from '../../../apollo/fragments'
import Image from 'next/image'
import {Modal} from 'reactstrap'
import CloseIcon from '../../../public/close-icon.png'
import HomeIcon from '../../../public/home-icon.png'
import useOrderRefresher from '../../../hooks/useOrderRefresher'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faHouseChimney,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import $ from 'jquery'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

// https://www.easypost.com/docs/api/node#addresses-create-codesample

const VERIFY_ADDRESS = gql`
  mutation VerifyAddress($input: AddressInput!) {
    verifyAddress(input: $input) {
      deliverability
      score
      level
    }
  }
`

const UPDATE_ADDRESS = gql`
  ${ORDER_FIELDS}
  mutation UpdateAddress(
    $input: AddressInput!
    $orderId: String!
  ) {
    updateAddress(input: $input, orderId: $orderId) {
      deliverability
      status
      message
      execution_time
      order {
        ...OrderFields
      }
    }
  }
`

const Address = ({state, setStorageState}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [status, setStatus] = useState('idle') // idle, pending, resolved, rejected
  // const [state, setstate] =
  //   useSessionStorageState(lookupStorageKey)
  // console.log(state, 'statesssfromupdateaddress')

  const array = state?.order?.order_metadata?.filter(function (
    obj,
  ) {
    return obj.key !== 'Emailed For'
  })
  // console.log(array, 'array')

  const [mutateAddress, {loading, error, data}] = useMutation(
    UPDATE_ADDRESS,
    {
      onCompleted: data => {
        const {status, deliverability} = data.updateAddress
        // console.log(status)
        if (status === 'success') {
          setStatus('resolved')
          setStorageState({
            ...state,
            order: data.updateAddress.order,
          })
          setModalOpen(false)
          $('#collapseThree').removeClass('show')
          //  setModalOpen(!modalOpen)
          // router.push(
          //   `/order/${state.orderNumber}/success?source=updated_address`,
          // )
        } else {
          setModalOpen(!modalOpen)
          setStatus('rejected')
        }
      },
    },
  )

  const [formIsValid, setFormIsValid] = useState(false)
  const [address, setAddress] = useState({
    first_name: '',
    last_name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postal_code: '',
  })

  const {order} = state

  useEffect(() => {
    // remove unneeded properties from address
    const {__typename, phone, company, country, ...address} =
      state?.order?.shipping
    setAddress({...address})
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const {completed, networkStatus} = useOrderRefresher()
  const [orderMeta, setOrderMeta] = useState(null)
  useEffect(() => {
    setOrderMeta(state.order.order_metadata)
    return () => {}
  }, [completed, networkStatus, state?.order?.order_metadata])

  useEffect(() => {
    let valid = true
    if (!address.first_name) valid = false
    if (!address.last_name) valid = false
    if (!address.address1) valid = false
    if (!address.city) valid = false
    if (!address.state) valid = false
    if (!address.postal_code) valid = false

    setFormIsValid(valid)
    return () => {}
  }, [address])

  if (!state.order) return null

  const {folder_id} = order

  function handleChange(e) {
    e.preventDefault()
    setAddress({
      ...address,
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  // useEffect(() => {

  // }, [])

  function saveAddress(e): void {
    toastr.options = {
      positionClass: 'toast-top-right',
      hideDuration: 300,
      timeOut: 3000,
    }

    e.preventDefault()
    const attValue = e.target.getAttribute('data-value')
    //  setStatus('pending')
    // console.log(attValue)
    if (attValue == 'confirm') {
      toastr.success(`Confirmed`)
      // setModalOpen(!modalOpen)
      mutateAddress({
        variables: {input: address, orderId: order.id},
      })
      // setstate({
      //   ...state,
      //   order: {...state.order, order_metadata: array},
      // })
    } else {
      toastr.success(`Saved`)
      mutateAddress({
        variables: {input: address, orderId: order.id},
      })
    }
  }

  // if (!state.order) router.push('/')

  const flaggedReason = order.order_metadata.find(
    v => v.key === 'Flagged Reason',
  )?.value
  const currentAction = actions.find(
    action => action.id === 'update-address',
  )
  if (
    !currentAction ||
    (!currentAction.folders.includes(folder_id) &&
      !currentAction.flag?.includes(flaggedReason) &&
      !currentAction?.flaggedFolders?.includes(folder_id))
  )
    return <UnavailableAction />

  const Deliverability = () => {
    if (!data) return null
    const {deliverability} = data.updateAddress

    switch (deliverability) {
      case 'deliverable':
        return (
          <p tw="text-sm bg-green-500 text-white p-4 my-2 rounded flex items-center">
            <CheckIcon tw="w-6 h-6 mr-2" />
            {/* {deliverability} */}
            Deliverable
          </p>
        )
      case 'deliverable_unnecessary_unit':
        return (
          <p tw="text-sm bg-green-500 text-white p-4 my-2 rounded flex items-center">
            <CheckIcon tw="w-6 h-6 mr-2" />
            {/* {deliverability} */}
            Deliverable
          </p>
        )

      default:
        return (
          <p tw="text-sm bg-red-500 text-white p-4 my-2 rounded flex items-center">
            <ExclamationCircleIcon tw="w-6 h-6 mr-2" />
            {deliverability}. Please update your address and try
            again.
          </p>
        )
    }
  }
  // console.log(address)

  // const updateAddress = async () => {
  //   fetch(
  //     'https://whifffresheners.myshopify.com/admin/api/2022-07/orders/4423800160316.json',
  //     {
  //       // mode: 'no-cors',
  //       method: 'PUT',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Access-Control-Allow-Origin': '*',
  //         'Access-Control-Allow-Methods':
  //           'GET,PUT,POST,DELETE,PATCH',
  //         'Access-Control-Allow-Headers': '*',
  //         Authorization: `Basic OTQwM2M0MDg1ZWFlNjA1ODdhYzNlMzkxZjlmN2IzNDE6c2hwcGFfMGI5YTNlYTFhMmJlZjkzYzZjNzNhMzUyNWE3ZGYwODk=`,
  //       },
  //       body: JSON.stringify({
  //         order: {
  //           id: 4423800160316,
  //           shipping_address: {
  //             address1: address.address1,
  //             address2: address.address2,
  //             city: address.city,
  //             province: address.state,
  //             country: 'United states',
  //             zip: address.postal_code,
  //           },
  //         },
  //       }),
  //     },
  //   )
  //     .then(response => {
  //       console.log(response)
  //       return response
  //     })
  //     .then(json => {
  //       console.log(json)
  //     })
  // }

  // const updateAddress = async () => {
  //   try {
  //     const res = await axios.put(
  //       'https://whifffresheners.myshopify.com/admin/api/2021-01/orders/4423800160316.json',
  //       JSON.stringify({
  //         order: {
  //           id: 4423800160316,
  //           shipping_address: {
  //             address1: address.address1,
  //             address2: address.address2,
  //             city: address.city,
  //             province: address.state,
  //             country: 'United states',
  //             zip: address.postal_code,
  //           },
  //         },
  //       }),
  //       {
  //         headers: {
  //           'Access-Control-Allow-Origin': '*',
  //           'Access-Control-Allow-Methods':
  //             'GET,PUT,POST,DELETE,PATCH',
  //           'Access-Control-Allow-Headers': '*',
  //           'Content-Type': 'application/json',
  //           Authorization: `Basic OTQwM2M0MDg1ZWFlNjA1ODdhYzNlMzkxZjlmN2IzNDE6c2hwcGFfMGI5YTNlYTFhMmJlZjkzYzZjNzNhMzUyNWE3ZGYwODk=`,
  //         },
  //       },
  //     )
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const orderfromlocal = JSON.parse(
    localStorage.getItem('dangle-it-crm:lookup-state'),
  )

  const orderMetaData = state.order.order_metadata
  const emailedFor = orderMetaData?.find(
    v => v.key === 'Emailed For',
  )?.value
  // console.log(emailedFor, 'emailedFor')

  return (
    <>
      {/* <ShadowBox> */}
      <div className="item-box">
        {/* <H2>Order Address</H2>
        <Subtitle>
          Enter &amp; save your new shipping address below.
        </Subtitle> */}
        {/* <Deliverability /> */}

        <form action="#" method="POST" className="address-form">
          <div tw="py-5 bg-white" className="pb-0">
            <div className="mob-gap" tw="grid grid-cols-6 gap-6">
              <div tw="col-span-6 sm:col-span-3">
                <Label id="first_name" text="First name" />
                <Text
                  value={address.first_name}
                  // defaultValue={state.order.shipping.first_name}
                  onChange={handleChange}
                  name="first_name"
                  autoComplete="given-name"
                />
              </div>

              <div tw="col-span-6 sm:col-span-3">
                <Label id="last_name" text="Last name" />
                <Text
                  value={address.last_name}
                  onChange={handleChange}
                  name="last_name"
                  autoComplete="family-name"
                />
              </div>

              <div tw="sm:col-span-3 col-span-6">
                <Label id="address1" text="Street address" />
                <Text
                  value={address.address1}
                  onChange={handleChange}
                  name="address1"
                  autoComplete="street-address"
                />
              </div>

              <div tw="sm:col-span-3 col-span-6">
                <Label
                  id="address2"
                  text="Apartment, Suite, Unit, etc."
                />
                <Text
                  value={address.address2}
                  onChange={handleChange}
                  name="address2"
                  autoComplete="off"
                />
              </div>

              <div tw="col-span-6 sm:col-span-6 lg:col-span-2">
                <Label id="city" text="City" />
                <Text
                  value={address.city}
                  onChange={handleChange}
                  name="city"
                />
              </div>

              <div tw="col-span-6 sm:col-span-3 lg:col-span-2">
                <Label id="state" text="State / Province" />
                <Text
                  value={address.state}
                  onChange={handleChange}
                  name="state"
                  placeholder="__"
                />
              </div>

              <div tw="col-span-6 sm:col-span-3 lg:col-span-2">
                <Label id="postal_code" text="ZIP / Postal" />
                <Text
                  value={address.postal_code}
                  onChange={handleChange}
                  name="postal_code"
                  placeholder="_____"
                  autoComplete="postal-code"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* </ShadowBox> */}
      <div tw="mx-auto text-center mt-4">
        <button
          className="btn brand-btn btn-width"
          key="verify-address"
          type="submit"
          // tw="inline-flex justify-center py-2 px-4 border shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-not-allowed disabled:opacity-60"
          css={[status === 'success' && tw`bg-green-600`]}
          // onClick={saveAddress}
          // onClick={() => setModalOpen(!modalOpen)}
          onClick={e => {
            saveAddress(e)
          }}
          // disabled={status === 'pending' || !formIsValid}
        >
          Save
          <span className="position-text">
            {status === 'pending' ? <Loading tw="ml-2" /> : null}
            {status === 'resolved' ? (
              <CheckIcon tw="pl-2 h-5 w-5" />
            ) : null}
          </span>
        </button>
        {emailedFor === 'Address' ? (
          <button
            className="btn brand-btn btn-width"
            onClick={saveAddress}
            data-value={'confirm'}
          >
            Confirm
          </button>
        ) : (
          ''
        )}
      </div>
      <Modal
        toggle={() => setModalOpen(!modalOpen)}
        isOpen={modalOpen}
        className="popup-dialog edit-item-popup"
      >
        {' '}
        <div className="p-2">
          <div className="top-header-item">
            <div className="d-flex align-items-center">
              <span className="mr-4 d-flex cart-icon">
                <FontAwesomeIcon icon={faHouseChimney} />
              </span>
              <span className="heading">Edit Address</span>
            </div>
            <span
              aria-hidden={true}
              onClick={() => {
                setModalOpen(!modalOpen)
              }}
              className="cursor-pointer close-btn"
            >
              <span className="close-icon d-flex cart-icon">
                <FontAwesomeIcon icon={faXmark} />
              </span>
            </span>
          </div>
        </div>
        <div className="modal-content-box ">
          <div className="pt-0 modal-body">
            <div css={[tw`pb-12 `]}>
              <form action="#" method="POST">
                <div tw="py-5 bg-white">
                  <div tw="grid grid-cols-6 gap-6">
                    <div tw="col-span-6 sm:col-span-3">
                      <Label id="first_name" text="First name" />
                      <Text
                        value={address.first_name}
                        // defaultValue={state.order.shipping.first_name}
                        onChange={handleChange}
                        name="first_name"
                        autoComplete="given-name"
                      />
                    </div>

                    <div tw="col-span-6 sm:col-span-3">
                      <Label id="last_name" text="Last name" />
                      <Text
                        value={address.last_name}
                        onChange={handleChange}
                        name="last_name"
                        autoComplete="family-name"
                      />
                    </div>

                    <div tw="sm:col-span-3 col-span-6">
                      <Label
                        id="address1"
                        text="Street address"
                      />
                      <Text
                        value={address.address1}
                        onChange={handleChange}
                        name="address1"
                        autoComplete="street-address"
                      />
                    </div>

                    <div tw="sm:col-span-3 col-span-6">
                      <Label
                        id="address2"
                        text="Apartment, Suite, Unit, etc."
                      />
                      <Text
                        value={address.address2}
                        onChange={handleChange}
                        name="address2"
                        autoComplete="off"
                      />
                    </div>

                    <div tw="col-span-6 sm:col-span-6 lg:col-span-2">
                      <Label id="city" text="City" />
                      <Text
                        value={address.city}
                        onChange={handleChange}
                        name="city"
                      />
                    </div>

                    <div tw="col-span-6 sm:col-span-3 lg:col-span-2">
                      <Label
                        id="state"
                        text="State / Province"
                      />
                      <Text
                        value={address.state}
                        onChange={handleChange}
                        name="state"
                        placeholder="__"
                      />
                    </div>

                    <div tw="col-span-6 sm:col-span-3 lg:col-span-2">
                      <Label
                        id="postal_code"
                        text="ZIP / Postal"
                      />
                      <Text
                        value={address.postal_code}
                        onChange={handleChange}
                        name="postal_code"
                        placeholder="_____"
                        autoComplete="postal-code"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="text-center">
              <button
                className="btn brand-btn popup-btn"
                onClick={e => {
                  saveAddress(e)
                  // updateAddress()
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

Address.getLayout = getLayout
Address.displayName = 'UpdateAddress'

export default Address

/*
<div tw="col-span-6 sm:col-span-3">
  <Label id="country" text="Country / Region" />
  <select
    id="country"
    name="country"
    autoComplete="country"
    tw="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
  >
    <option>United States</option>
    <option>Canada</option>
    <option>Mexico</option>
  </select>
</div>
*/
