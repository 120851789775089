import {getLayout} from '../../../components/OrderLayout'
import {lookupStorageKey} from '../../../apollo/cache'
import {useSessionStorageState} from '../../../utils/useSessionStorageState'
import H2 from '../../../components/headings/H2'
import {OrderItem} from '../../../components/OrderItem'
import useOrderItem from '../../../hooks/useOrderItem'
import React, {useEffect, useState} from 'react'
import Client from 'shopify-buy'
import {useRouter} from 'next/router'
import {CheckIcon, XIcon} from '@heroicons/react/solid'
import {Loading} from '../../../components/Loading'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import ShadowBox from '../../../components/layout/ShadowBox'
import Button from '../../../components/Button'
import Subtitle from '../../../components/headings/Subtitle'

const shopifyClient = Client.buildClient({
  domain: 'whifffresheners.myshopify.com', //`${process.env.SHOPIFY_STORE}.myshopify.com`,
  storefrontAccessToken: '8d8be545cfd02316b7b9480d71eb14ed', //process.env.SHOPIFY_STOREFRONT_TOKEN,
})

const IncDinc = styled.button([
  tw`relative inline-flex items-center px-2 py-1 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500`,
])

const initialState = {
  orderNumber: '',
  email: '',
  order: '',
}

const isSSR = typeof window === 'undefined'

interface StateType {}

const Reorder = ({shQuantity}) => {  
  // const router = useRouter()
  const [status, setStatus] = useState('idle') // idle, pending, resolved, rejected
  const [orderItems, setOrderItems] = useState()
  const [checkout, setCheckout] =
    useState<ShopifyBuy.Cart | null>(null)
  // console.log(checkout, 'checkoutreorder')
  const [checkoutUrl, setCheckoutUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [checkedItems, setCheckedItems] = useState([])
  const [state] = useSessionStorageState(
    lookupStorageKey,
    initialState,
  )
  const [cartItems, setCartItems] = useState([])
  const [newScent, setnewScent] = useState([])

  // console.log(cartItems)

  const handleAddToCart = (event, clickedItem) => {
    console.log('add')
    setCartItems(prev => {
      // 1. Is the item already added in the cart?
      const isItemInCart = prev.find(
        item => +item.id === +clickedItem.id,
      )

      if (isItemInCart) {
        return prev.map(item =>
          +item.id === +clickedItem.id
            ? {...item, quantity: item.quantity + 1}
            : item,
        )
      }
      // First time the item is added
      return [...prev, {...clickedItem}]
    })
  }

  const handleToCart = (event, clickedItem) => {    
    setCartItems(prev => {
      // 1. Is the item already added in the cart?
      const isItemInCart = prev.find(
        item => +item.id === +clickedItem.id,
      )

      if (isItemInCart) {
        return prev.map(item =>
          +item.id === +clickedItem.id
            ? {
                ...item,
                quantity: Number(event.target.value),
              }
            : item,
        )
      }
      // First time the item is added
      return [...prev, {...clickedItem}]
    })
  }

  const handleRemoveFromCart = (event, id: number) => {
    console.log('remove')
    setCartItems(prev =>
      prev.reduce((ack, item) => {
        if (item.id === id) {
          if (item.quantity === 1) return ack
          return [...ack, {...item, quantity: item.quantity - 1}]
        } else {
          return [...ack, item]
        }
      }, []),
    )
  }

  let lines = []

  const {order} = state
  const {order_items} = order
  // console.log(order, 'orderinreorder')

  async function createShopifyCheckout() {
    const checkout = await shopifyClient.checkout
      .create()
      .then(checkout => checkout)
    setCheckout(checkout)
  }

  async function getVariants() {
    return await shopifyClient.product
      .fetchAll()
      .then(products => {
        // console.log(products, 'products')
        return products.find(item => {
          item.title === 'Custom Air Freshener',
            setnewScent(item.options[0].values)
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    getVariants()
  }, [])

  useEffect(() => {
    createShopifyCheckout()
    return () => {}
  }, [])

  if (!state.order) return null

  async function buyIt() {
    // setLoading(true)
    setStatus('pending')

    return await shopifyClient.product
      .fetchAll()
      .then(products =>
        products.find(
          item => item.title === 'Custom Air Freshener',
        ),
      )
      .then(product => {
        return cartItems.map(cartItem => {
          // const {image, scent, crop} = useOrderItem(cartItem)
          let scent = cartItem.variation_list.find(
            v => v.key === 'Scent',
          ).value
          scent =
            scent === 'Island Breeze'
              ? 'Island Breeze (most popular)'
              : scent
          let other_crop = ''
          let crop = cartItem.variation_list.find(
            v => v.key === 'Crop',
          ).value
          if(crop != 'Just The Head(s)' && crop != 'Entire Body(s)' && crop != 'Do Not Crop' && crop != 'Just The Car') {
            other_crop = crop
            crop = 'Other'
          }

          const image = cartItem.metadata.find(
            v => v.key === 'image',
          ).value
          const variant = product.variants.find(
            variant => variant.title === `${scent} / ${crop}`,
          )
          lines.push({
            variantId: variant.id,
            quantity: cartItem.quantity,
            customAttributes: [
              {key: 'Uploaded Image', value: image},
            ],
          })
          return cartItem
        }) // checked products map
      })
      .then(async variant => {
        const res = await shopifyClient.checkout.addLineItems(
          checkout.id,
          lines,
        )
        // setLoading(false)
        setStatus('resolved')
        setCheckoutUrl(
          // @ts-ignore
          checkout.webUrl.replace(
            'whifffresheners.myshopify',
            'dangleit',
          ),
        )
        window.open(
          // @ts-ignore
          checkout.webUrl.replace(
            'whifffresheners.myshopify',
            'dangleit',
          ),
          '_blank',
        )
      })
      .catch(error => {
        setStatus('rejected')
        console.log('error? ', error)
      })
  }

  const ReorderItem = ({item, cartItems}) => {
    const [checked, setnewchecked] = useState(
      cartItems?.find(i => +i.id === +item.id),
    )
    
    if (
      item.variation_list.find(v => v.key === 'Replace-Refund')
    )
      return null
    // const checked = cartItems?.find(i => +i.id === +item.id)
    const styles = {
      backgroundColor: '#F3F4F6',
    }

    const ItemScent = item.variation_list?.find(
      v => v.key === 'Scent',
    )?.value

    const getScantAvailiblity = newScent?.find(
      v => v.value === ItemScent,
    )

    // useEffect(() => {
    //   setnewQuantites(checked?.quantity)
    // }, [])
    return (
      <div
        className="border-box item-outer"
        // css={[
        //   tw`mt-4 -mx-2 p-2 hover:bg-gray-100`,
        //   checked && tw`bg-gray-100`,
        // ]}
      >
        {getScantAvailiblity == 'undefined' ? (
          <div className="banner-text">Scent Discontinued</div>
        ) : null}
        {/* <input
          type="checkbox"
          css={[
            tw`opacity-0 absolute h-8 w-8`,
            checked && tw`pointer-events-none`,
          ]}
          value={item.id}
          id={`item-${item.id}`}
          name={`item-${item.id}`}
          checked={checked}
          onChange={event => {
            checked
              ? handleRemoveFromCart(event, item.id)
              : handleAddToCart(event, item)
          }}
        /> */}
        <label
          className="reorder-flex-mob"
          tw="relative flex items-start md:items-center cursor-pointer flex-wrap"
          htmlFor={`item-${item.id}`}
          onClick={() => {
            return false
          }}
        >
          <OrderItem
          shQuantity={shQuantity}
            item={item}
            hasPrice={true}
            showQuantity={false}
            setOrderItems={setOrderItems}
            // order_items={order_items}
            states={state}
          >
            {/* {checked ? ( */}
            <>
              <div className="qty-box">
                <span
                  className="qty-box-reorder"
                  tw="place-self-start inline-flex lg:pt-2 lg:mr-4 lg:relative absolute top-0 right-0"
                >
                  <IncDinc
                    type="button"
                    tw="rounded-l-md"
                    onClick={e =>
                      handleRemoveFromCart(e, item.id)
                    }
                  >
                    -
                  </IncDinc>
                  {/* <span
                    className="qty-input"
                    tw="-ml-px w-8 relative inline-flex items-center px-2 py-1 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  >
                    {checked?.quantity || 0}
                  </span> */}
                  <input
                    // autoFocus
                    className="qty-input"
                    tw="-ml-px w-8 relative inline-flex items-center px-2 py-1 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                    value={checked?.quantity || 0}
                    onChange={e => {
                      // setnewchecked({
                      //   ...checked,
                      //   quantity: Number(e.target.value),
                      // })
                      handleToCart(e, item)
                    }}
                  />
                  <IncDinc
                    type="button"
                    tw="rounded-r-md -ml-px "
                    onClick={e => handleAddToCart(e, item)}
                  >
                    +
                  </IncDinc>
                </span>
              </div>
              {/* <CheckIcon tw="mr-3 h-6 w-6" /> */}
            </>
            {/* ) : null}*/}
          </OrderItem>
        </label>
      </div>
    )
  }

  return (
    <>
      {/* <ShadowBox> */}
      <div className="item-box px-0">
        {/* <H2>Items:</H2>
        <Subtitle>Ordered items and their details.</Subtitle> */}

        {order_items.map(item => (
          <ReorderItem
            key={item.id}
            item={item}
            cartItems={cartItems}
          />
        ))}
      </div>
      {/* </ShadowBox> */}

      <div tw="grid place-items-center mx-auto text-center mt-4">
        <button
          className="brand-btn"
          key="verify-address"
          type="submit"
          css={[
            status === 'success' && tw`bg-green-600`,
            status === 'rejected' && tw`bg-red-600`,
          ]}
          onClick={buyIt}
          disabled={status === 'pending' || !cartItems.length}
        >
          Add To Cart
          <span className="position-text">
            {status === 'pending' ? <Loading tw="ml-2" /> : null}
            {/* {status === 'resolved' ? (
              <CheckIcon tw="pl-2 h-5 w-5" />
            ) : null}
            {status === 'rejected' ? (
              <XIcon tw="pl-2 h-5 w-5" />
            ) : null} */}
          </span>
        </button>
        {status === 'rejected' && (
          <Subtitle tw="mt-1">
            We were unable to add your item(s) to the cart.
          </Subtitle>
        )}
        {checkoutUrl && (
          <a
            href={checkoutUrl}
            target="_blank"
            rel="noreferrer"
            className="brand-btn mt-3"
          >
            View Cart
          </a>
        )}
      </div>
    </>
  )
}

Reorder.getLayout = getLayout
Reorder.displayName = 'Reorder'

export default Reorder
