import Link from 'next/link'
import styles from './Sidebar.module.css'
import {CheckIcon, XIcon} from '@heroicons/react/outline'
import {useSessionStorageState} from '../utils/useSessionStorageState'
import {initialState, lookupStorageKey} from '../apollo/cache'
import useDeliveryStatus from '../hooks/useDeliveryStatus'
import {useEffect} from 'react'
import tw from 'twin.macro'
import Loader from './Loader'

export const navigation = [
  {
    name: 'Canceled',
    icon: XIcon,
    folders: [399366],
    between: [
      399370,
      399341,
      399342,
      399343, // Confirmed Status
      435734,
      435735,
      435757,
      435733, // Shipped Status
      435744,
      435745,
      435746,
      435747,
      435748,
      435755,
      435756, // Prepared Status
      399348,
      433467,
      433468,
      433469,
      433470,
      433471,
      435743, // In Production status
      399342,
      399343,
      399345,
      399346,
      399347, // With Designer Status
    ],
    // folders: [
    //   435734, 435735, 399369, 435736, 435738, 435737, 399370,
    //   399341, 399342, 399343, 399344, 399345, 399346, 399347,
    //   399348, 433467, 433468, 433469, 433470, 433471, 435743,
    //   435744, 435745, 435746, 435747, 435748, 435755, 435756,
    //   435757, 435733, 399366,
    // ],
    // between: [
    //   435734, 435735, 399369, 435736, 435738, 435737, 399342,
    //   399343, 399344, 399345, 399346, 399347, 399348, 433467,
    //   433468, 433469, 433470, 433471, 435743, 435744, 435745,
    //   435746, 435747, 435748, 435755, 435756, 435757, 435733,
    //   399366,
    // ],
  },
  {
    name: 'Confirmed',
    icon: CheckIcon,
    folders: [399370, 399341],
    between: [
      435734,
      435735,
      435757,
      435733, // Shipped Status
      435744,
      435745,
      435746,
      435747,
      435748,
      435755,
      435756, // Prepared Status
      399348,
      433467,
      433468,
      433469,
      433470,
      433471,
      435743, // In Production status
      399342,
      399343,
      399345,
      399346,
      399347, // With Designer Status
    ],
    // folders: [
    //   435734, 435735, 399369, 435736, 435738, 435737, 399370,
    //   399341, 399342, 399343, 399344, 399345, 399346, 399347,
    //   399348, 433467, 433468, 433469, 433470, 433471, 435743,
    //   435744, 435745, 435746, 435747, 435748, 435755, 435756,
    //   435757, 435733, 399366,
    // ],
    // between: [
    //   435734, 435735, 399369, 435736, 435738, 435737, 399342,
    //   399343, 399344, 399345, 399346, 399347, 399348, 433467,
    //   433468, 433469, 433470, 433471, 435743, 435744, 435745,
    //   435746, 435747, 435748, 435755, 435756, 435757, 435733,
    //   399366,
    // ],
  },
  {
    name: 'With Designers',
    icon: CheckIcon,
    // icon: ScissorsIcon,
    folders: [399342, 399343, 399344, 399345, 399346, 399347],
    between: [
      435734,
      435735,
      435757,
      435733, // Shipped Status
      435744,
      435745,
      435746,
      435747,
      435748,
      435755,
      435756, // Prepared Status
      399348,
      433467,
      433468,
      433469,
      433470,
      433471,
      435743, // In Production status
    ],
    // folders: [
    //   435734, 435735, 399343, 399344, 399345, 399346, 399347,
    //   399348, 433467, 433468, 433469, 433470, 433471, 435743,
    //   435744, 435745, 435746, 435747, 435748, 435755, 435756,
    //   435757, 435733,
    // ],
    // between: [
    //   435734, 435735, 399348, 433467, 433468, 433469, 433470,
    //   433471, 435743, 435744, 435745, 435746, 435747, 435748,
    //   435755, 435756, 435757, 435733,
    // ],
  },
  {
    name: 'In Production',
    // icon: CogIcon,
    icon: CheckIcon,
    folders: [
      399348, 433467, 433468, 433469, 433470, 433471, 435743,
      435744, 435745, 435746, 435747, 435748, 435755, 435756,
    ],
    between: [
      435734,
      435735,
      435757,
      435733, // Shipped Status
    ],
    // folders: [
    //   435734, 435735, 399348, 433467, 433468, 433469, 433470,
    //   433471, 435743, 435744, 435745, 435746, 435747, 435748,
    //   435755, 435756, 435757, 435733,
    // ],
    // between: [
    //   435734, 435735, 435743, 435744, 435745, 435746, 435747,
    //   435748, 435755, 435756, 435757, 435733,
    // ],
  },
  {
    name: 'Prepared',
    // icon: CubeIcon,
    icon: CheckIcon,
    folders: [435757],
    between: [
      435734,
      435735,
      435733, // Shipped Statues
    ],
    // folders: [
    //   435734, 435735, 435744, 435745, 435746, 435747, 435748,
    //   435755, 435756, 435757, 435733,
    // ],
    // between: [435734, 435735, 435755, 435757, 435733],
  },
  {
    name: 'Shipped',
    // icon: TruckIcon,
    icon: CheckIcon,
    folders: [435734, 435735, 435733],
    between: [],
    // folders: [435734, 435735, 435733],
    // between: [435734, 435735],
  },
  {
    name: 'Delivered',
    // icon: HomeIcon,
    icon: CheckIcon,
    folders: [],
    between: [],
  },
]

const OrderStatus = ({state, setdelStatus, success = null}) => {
  // console.log(state, 'statefromorderstatus')
  const orderfromlocal = JSON.parse(
    localStorage.getItem('dangle-it-crm:lookup-state'),
  )
  // console.log(orderfromlocal, 'orderfromlocal')
  const {order} = state
  const {order_shipments, folder_id, order_metadata} = order
  const {checkDeliveryStatus, status} = useDeliveryStatus(
    order.id,
  )

  useEffect(() => {
    if (!order_shipments) return

    let delivery_status = order_metadata.find(
      v => v.key === 'delivery_status',
    )?.value
    if (delivery_status) {
      delivery_status = JSON.parse(delivery_status)
      // console.log('UE Delivery STatus: ', delivery_status)
    }
    checkDeliveryStatus(order_shipments[0], order_metadata)
    return () => {}
  })

  useEffect(() => {
    setdelStatus(status)
  }, [status])

  const Tracking = ({shipment}) => {
    const {tracking_number, tracking_url} = shipment
    // console.log(shipment, 'shipment')

    // console.log(tracking_number, 'tracking_number')

    return (
      <>
        {[435733, 435734, 435735].includes(folder_id) && (
          <p className="tack-text ">
            <span
              className="tacking-link"
              tw="bottom-0 transform block text-xs text-gray-400 truncate md:overflow-visible"
            >
              Tracking&nbsp;:&nbsp;
              <a
                href={tracking_url}
                target="_blank"
                rel="noreferrer"
                style={{maxWidth: '160px'}}
              >
                <span tw="underline hover:text-brand">
                  {tracking_number}
                </span>
              </a>
            </span>
            {/* Tracking: <span tw="underline">{tracking_number}</span> */}
          </p>
        )}
      </>
    )
  }

  let delivery_status = state.order.order_metadata?.find(
    v => v.key === 'delivery_status',
  )?.value
  // console.log('UEDeliverySTatus', delivery_status)

  if (delivery_status) {
    delivery_status = JSON.parse(delivery_status)
    // console.log('DeliverysTatusorderstatus ', delivery_status)
  }

  const cancelOrder = order?.order_metadata?.find(
    v => v.key === 'Cancel',
  )?.value
  const shopifyCancel = order?.order_metadata?.find(
    v => v.key === 'shopify_cancelled_at',
  )?.value
  const cancelOrderDate = order?.order_metadata?.find(
    v => v.key === 'order_canceled_on',
  )?.value

  const emailedFor = state.order.order_metadata?.find(
    v => v.key === 'Emailed For',
  )?.value

  // console.log('order detail', order);
  // console.log('delievery status', delivery_status?.status);
  // console.log('Statusssss', status)

  const convertDate = value => {
    const dateConvo = new Date(
      value?.toString().replace(/ /g, 'T'),
    )

    let d: string | number = dateConvo.getDate()
    let m: string | number = dateConvo.getMonth() + 1
    let y = dateConvo.getFullYear()

    if (m.toString().length === 1) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      m = '0' + m.toString()
    }
    if (d.toString().length === 1) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      d = '0' + d.toString()
    }

    // setting up date format - mm/dd/yy 2022-7-28
    const finalDate = y.toString() + '-' + m + '-' + d
    // console.log(finalDate.replace(/ /g, 'T'),"finalDate")
    return finalDate
  }

  const checkStatus = (item, emailedFor) => {
    // Delivered Status
    if (
      item.name == 'Delivered' &&
      status?.status == 'delivered'
    ) {
      return styles.currentStatus
    } else if (
      item.name != 'Delivered' &&
      status?.status == 'delivered'
    ) {
      return styles.currentStatus + ' ' + styles.between
    }

    // When emailed_for equals 'Photo'
    if (emailedFor == 'Photo') {
      if (item.name == 'Confirmed') {
        return styles.currentStatus
      } else {
        return ''
      }
    }

    // When emailed_for equals 'Address'
    if (emailedFor == 'Address') {
      if (item.name == 'In Production') {
        return styles.currentStatus
      } else if (
        item.name == 'With Designers' ||
        item.name == 'Confirmed'
      ) {
        return styles.currentStatus + ' ' + styles.between
      }
      return ''
    }

    if (item.folders.includes(folder_id)) {
      return styles.currentStatus
    } else if (item.between.includes(folder_id)) {
      return styles.currentStatus + ' ' + styles.between
    }

    return ''
  }

  const isCanceled =
    success === 'success' ||
    cancelOrder ||
    cancelOrderDate ||
    shopifyCancel
  return (
    <nav tw="mt-5 flex-1 space-y-3" className="sidebar-nav">
      {navigation.map(item => {
        if (item.name == 'Canceled' && !isCanceled) return <></>
        if (item.name != 'Canceled' && isCanceled) return <></>

        return (
          <>
            {/* {console.log(item, 'itemorder')} */}
            <h3
              key={item.name}
              data-status={status?.status}
              css={[
                tw`relative flex flex-wrap items-center px-2 py-2`,
              ]}
              className={`
              ${styles.statusNav__item}
              ${checkStatus(item, emailedFor)}
            `}
            >
              {/* {console.log(status?.status, 'status?.status')} */}
              {/* <item.icon
              tw="mr-3 h-10 w-10 rounded-full border-current p-1"
              aria-hidden="true"
            /> */}
              <span className="status-icon">
                <item.icon
                  tw="mr-3 h-10 w-10 border-current p-1"
                  aria-hidden="true"
                />
              </span>
              <span
                tw="ml-2 flex-1 relative "
                className={styles.statusText}
              >
                <span className="pd-top">{item.name}</span>
                {item.folders.includes(folder_id) ||
                emailedFor == 'Photo'
                  ? item?.name === 'Confirmed' && (
                      <span className="order-status-text">
                        Your order has been confirmed and placed
                        in the design queue
                      </span>
                    )
                  : ''}
                {item.folders.includes(folder_id) ? (
                  item?.name === 'Canceled' ? (
                    success === 'success' ? (
                      <span className="newclass">
                        Order Canceled via Order Portal{' '}
                        {new Date(
                          convertDate(order?.date_added),
                        ).toLocaleDateString('en-US', {
                          weekday: 'short',
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                      </span>
                    ) : cancelOrder ? (
                      <span className="newclass">
                        Order Canceled via Order Portal
                      </span>
                    ) : cancelOrderDate ? (
                      <span className="newclass">
                        Order Canceled via Admin on{' '}
                        {new Date(
                          cancelOrderDate?.replaceAll('-', '/'),
                        ).toLocaleDateString('en-US', {
                          weekday: 'short',
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                      </span>
                    ) : shopifyCancel ? (
                      <span className="newclass">
                        Order Canceled via Shopify on{' '}
                        {new Date(
                          convertDate(shopifyCancel),
                        ).toLocaleDateString('en-US', {
                          weekday: 'short',
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                      </span>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                {item.folders.includes(folder_id)
                  ? item?.name === 'With Designers' && (
                      <span className="newclass">
                        Our talented designers are getting your
                        order ready for production
                      </span>
                    )
                  : ''}
                {item.folders.includes(folder_id) ||
                emailedFor == 'Address'
                  ? item?.name === 'In Production' && (
                      <span className="newclass">
                        Your order is being carefully crafted
                        based on your specifications
                      </span>
                    )
                  : ''}
                {item.folders.includes(folder_id)
                  ? item?.name === 'Prepared' && (
                      <span className="newclass">
                        Your order has been crafted and is
                        getting ready to ship
                      </span>
                    )
                  : ''}
                {
                  // status?.status !== 'delivered' ||
                  // folder_id === 435757 || folder_id === 435733
                  //   ?
                  item.name === 'Shipped' &&
                    order_shipments?.map(item => (
                      <>
                        <Tracking
                          key={item?.id}
                          shipment={item}
                        />
                      </>
                    ))
                  // : null
                }

                {status?.status === 'delivered'
                  ? item?.name === 'Delivered' && (
                      <span className="newclass">
                        Your order has been delivered, if you
                        have any questions or concerns, please
                        don’t hesitate to get in touch
                      </span>
                    )
                  : ''}
              </span>
            </h3>
          </>
        )
      })}
    </nav>
  )
}

export default OrderStatus
