import Link from 'next/link'
import {useRouter} from 'next/router'
import H2 from './headings/H2'

const UnavailableAction = () => {
  const router = useRouter()
  const {query} = router
  const {id} = query
  
  return (
    <H2>
      This action is currently unavailable for this order.{' '}
      <Link href={`/order/${id}`}>
        <a tw="underline cursor-pointer">go back</a>
      </Link>
    </H2>
  )
}

export default UnavailableAction
