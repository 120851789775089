// import Link from 'next/link'
// import styled from '@emotion/styled'
import Image from 'next/image'
import {useRouter} from 'next/router'
import React, {useEffect, useRef, useState} from 'react'
import SidebarNavMobile from './SidebarNavMobile'
import SidebarNavDesktop from './SidebarNavDesktop'
import {MenuIcon} from '@heroicons/react/outline'
import tw from 'twin.macro'
import MainHeader from './MainHeader'
import OrderHeader from './OrderHeader'
import Link from 'next/link'
import OrderStatus from './OrderStatus'
import CalendarIcon from '../public/calendar.png'
import FileIcon from '../public/file-icon.png'
import HomeIcon from '../public/home-icon.png'
import HomeDisIcon from '../public/home-icon-disable.png'
import ItemListIcon from '../public/item-list.png'
import ItemListDisIcon from '../public/item-list-disabled.png'
import ReplacementIcon from '../public/replacement-item.png'
import ReplacementDisIcon from '../public/replacement-item-disabled.png'
import OrderIcon from '../public/order-icon.png'
import OrderDisIcon from '../public/order-icon-disabled.png'
import OrderDetails from '../pages/order/[id]/order-details'
import UpdateItems from '../pages/order/[id]/update-items'
import UpdateAddress from '../pages/order/[id]/update-address'
import Reorder from '../pages/order/[id]/reorder'
import Refund from '../pages/order/[id]/refund'
import {useSessionStorageState} from '../utils/useSessionStorageState'
import {initialState, lookupStorageKey} from '../apollo/cache'
import CancelOrder from '../pages/order/[id]/cancel-order'
import CloseIcon from '../public/close-icon.png'
import {Modal} from 'reactstrap'
import Loader from '../components/Loader'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faCalendarCheck,
  faList,
  faFileLines,
  faHouseChimney,
  faRightLeft,
  faCartShopping,
  faXmark,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons'
import useDeliveryStatus from '../hooks/useDeliveryStatus'
import {isMobile} from 'react-device-detect'

declare let $: any

const SiteLayout = ({children}) => {
  const [shQuantity, setShQuantity] = useState(false)
  const [modalCancel, setModalCancel] = useState(false)
  const toggleShow = () => setModalCancel(p => !p)
  const [isToggled, setToggle] = useState(false)
  const [itemValue, setItemValue] = useState('Item')
  const [match, setmatch] = useState(false)
  const toggleItem = () => {
    setToggle(!isToggled)
    isToggled
      ? setItemValue('Items')
      : setItemValue('Edit Items')
  }
  const [addressValue, setAddressValue] = useState(
    'Shipping Address',
  )
  const [delStatus, setdelStatus] = useState({
    attempt: '',
    status: '',
    last_detail: {datetime: ''},
  })
  const [refundStatus, setrefundStatus] = useState(false)

  setTimeout(function () {}, 2000)

  const toggleAddress = () => {
    setToggle(!isToggled)
    isToggled
      ? setAddressValue('Shipping Address')
      : setAddressValue('Edit Address')
  }

  const [success, setSuccess] = useState(null)

  //toggle button value
  const router = useRouter()
  const {query} = router
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [state, setStorageState] = useSessionStorageState(
    lookupStorageKey,
    initialState,
  )

  const {order} = state

  const {checkDeliveryStatus, status} = useDeliveryStatus(
    order.id,
  )

  const convertDate = value => {
    const dateConvo = new Date(
      value?.toString().replace(/ /g, 'T'),
    )

    let d: string | number = dateConvo.getDate()
    let m: string | number = dateConvo.getMonth() + 1
    let y = dateConvo.getFullYear()

    if (m.toString().length === 1) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      m = '0' + m.toString()
    }
    if (d.toString().length === 1) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      d = '0' + d.toString()
    }

    // setting up date format - mm/dd/yy 2022-7-28
    const finalDate = y.toString() + '-' + m + '-' + d
    // console.log(finalDate.replace(/ /g, 'T'),"finalDate")
    return finalDate
  }

  const orderMeta = state.order.order_metadata
  const emailedFor = orderMeta?.find(
    v => v.key === 'Emailed For',
  )?.value
  // console.log(emailedFor, 'emailedFor')
  const OrderItems = state.order.order_items

  //delievery status
  // const [delivery_status, setdelivery_status] = useState(
  //   state?.order?.order_metadata?.find(
  //     v => v.key === 'delivery_status',
  //   )?.value,
  // )
  // const [diffDaysForDelivery, setdiffDaysForDelivery] =
  //   useState(0)
  // console.log('diffDaysForDelivery', diffDaysForDelivery)
  // console.log('UE Delivery STatus: ', delivery_status)

  // useEffect(() => {
  //   if (typeof delivery_status === 'string') {
  //     console.log('UE Delivery STatus: 22', delivery_status)
  //     setdelivery_status(JSON.parse(delivery_status))
  //     console.log('UE Delivery STatus: ', delivery_status)
  //   }
  //   return null
  // }, [delivery_status])

  // useEffect(() => {
  //   if (delivery_status?.attempt !== null) {
  //     const date11 = new Date(delivery_status?.attempt)
  //     const date22 = new Date()
  //     const diffTime = Math.abs(+date22 - +date11)
  //     setdiffDaysForDelivery(
  //       Math.ceil(diffTime / (1000 * 60 * 60 * 24)),
  //     )
  //     console.log(delivery_status?.attempt, 'date11')
  //     console.log(date11, 'date11')
  //     console.log(diffTime, 'diffTime')
  //   }
  //   return null
  // }, [delivery_status])

  //window width
  const [activeMenu, setActiveMenu] = useState(false)
  const [screenSize, setScreenSize] = useState(undefined)
  const [loads, setLoads] = useState(true)

  const [windowWidth, setWindowWidth] = useState(1024)
  const [windowHeight, setWindowHeight] = useState(1024)

  //check if its a replacement order or not
  const replacementSuffix = [
    'DS',
    'DS',
    'LIT',
    'DIT',
    'RP',
    'RP',
    'LIT',
  ]
  // let str
  // str = state?.orderNumber

  // useEffect(() => {
  //   if (replacementSuffix.some(v => str.includes(v))) {
  //     setmatch(true)
  //   } else {
  //     // console.log(`No match using "${str}"`)
  //   }
  // }, [str, ])

  useEffect(() => {
    setTimeout(() => {
      setLoads(false)
    }, 3000)
  }, [])

  useEffect(() => {
    var hours = 24
    var now = Date.now()

    if (!state.authenticated) {
      localStorage.removeItem('dangle-it-crm:lookup-state')
      window.location.href = '/'
    }

    // else if(now - state.timestamp > hours * 60 * 60 * 1000) {
    //   localStorage.removeItem('dangle-it-crm:lookup-state')
    //   window.location.href = '/'
    // }

    {
      const orderNumber = state?.orderNumber
      const currentOrderId = router.query.id
      if (currentOrderId != orderNumber) {
        router.push(`/order/${state.orderNumber}`)
      }
    }

    const handleResize = () => {
      setScreenSize(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    handleResize()

    return () =>
      window.removeEventListener('resize', handleResize)
  }, [state, delStatus])

  useEffect(() => {
    if (screenSize <= 992) setActiveMenu(true)
    else setActiveMenu(false)

    setWindowWidth(window.innerWidth)
    setWindowHeight(window.innerHeight)
  }, [screenSize])

  var flagVal = ''
  var scentVal
  if (OrderItems?.length > 0) {
    OrderItems?.forEach(item => {
      item.variation_list?.forEach(itemVariation => {
        if (itemVariation.key == 'Flag') {
          // flagVal += itemVariation.value
          flagVal = itemVariation.value
        }
      })
    })
  }
  if (flagVal !== 'undefined') {
    flagVal = flagVal
  } else {
    flagVal = ''
  }

  const order_shipments = state.order.order_shipments
  const date_shipped = order_shipments?.find(
    v => v.date_added, // .date_shipped replaced with this
  )?.date_added // .date_shipped replaced with this
  // console.log(date_shipped,"date_shipped234")

  let shippedDate = new Date(date_shipped)
  let endDate = shippedDate.setDate(shippedDate.getDate() + 10)
  let Enddate = new Date(endDate).toLocaleDateString('fr-CA')

  const date1 = new Date(date_shipped)
  const date2 = new Date()
  const diffTime = Math.abs(+date2 - +date1)
  var diffDaysForShipping = Math.floor(
    diffTime / (1000 * 60 * 60 * 24),
  )

  let delivery_status = state?.order?.order_metadata?.find(
    v => v.key === 'delivery_status',
  )?.value

  //get REPLACMENT REQUEST response from OD
  const extended = order?.order_metadata?.find(
    v => v.key === 'ExtendRequestUntil',
  )?.value
  const isReturnable =
    new Date(extended?.replaceAll('-', '/')).getTime() -
    new Date().getTime()

  //get refund response from OD
  const refundResponse = order?.order_metadata?.find(
    v => v.key === 'Partially Refunding',
  )?.value
  const fullyRefundResponse = order?.order_metadata?.find(
    v => v.key === 'Fully Refunding',
  )?.value
  const statusBanner = order?.order_metadata?.find(
    v => v.key === 'order_status_banner_text',
  )?.value
  const shopifyCancel = order?.order_metadata?.find(
    v => v.key === 'shopify_cancelled_at',
  )?.value
  const cancelOrder = order?.order_metadata?.find(
    v => v.key === 'Cancel',
  )?.value
  const cancelOrderDate = order?.order_metadata?.find(
    v => v.key === 'order_canceled_on',
  )?.value

  const index = order?.orderNumber?.includes(replacementSuffix)

  //logout
  const logoutUser = () => {
    localStorage.removeItem('dangle-it-crm:lookup-state')
    router.push('/')
  }

  const dateChange = delStatus?.last_detail?.datetime
  // console.log(dateChange, 'dateChange')

  const date123 = new Date(dateChange)
  const date234 = new Date()
  const diffTimes = Math.abs(+date234 - +date123)
  var diffDaysForTracking = Math.floor(
    diffTimes / (1000 * 60 * 60 * 24),
  )

  if (delStatus?.status == 'delivered') {
    const date11 = new Date(dateChange)
    const date22 = new Date()
    const diffTime = Math.abs(+date22 - +date11)
    var diffDaysForDelivery = Math.floor(
      diffTime / (1000 * 60 * 60 * 24),
    )
  }

  const collapseSection = target => {
    if (windowWidth <= 992 && isMobile) {
      if (target == 'sidebar-collapse') {
        $('div#accordion div.card div.collapse.show').collapse(
          'hide',
        )
      } else {
        $('div#sidebar-collapse').collapse('hide')
      }
    }
  }

  // console.log('test')

  // console.log('diffDaysForDelivery', diffDaysForDelivery)
  // console.log('diffDaysForTracking', diffDaysForTracking)
  // console.log('diffDaysForShipping', diffDaysForShipping)
  // console.log('order', order)
  // console.log('delStatus', delStatus)
  // console.log('refundStatus', refundStatus)
  // console.log('extended', new Date(extended).getTime())
  // console.log(new Date().getTime())
  // console.log(new Date(extended).getTime() > new Date().getTime())

  return (
    state.authenticated && (
      <div className="custom_site_layout customBgColor">
        {loads && (
          <div className="loader-box">
            <Loader />
          </div>
        )}
        <div className="top-header-box ">
          <MainHeader orderID={query?.id} order={order} />
          <OrderHeader orderID={query?.id} order={order} />
        </div>
        <div className="pb-5 ">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div id="acc-sidebar" className="accordian-box">
                  <div className="card">
                    <div
                      className="card-header"
                      id="sidebar-heading"
                      onClick={() =>
                        collapseSection('sidebar-collapse')
                      }
                    >
                      {/* {success === 'success' &&
                      !cancelOrder &&
                      !shopifyCancel ? (
                        <div className="banner-text sidebar-banner ">
                          Order Canceled via Order Portal{' '}
                          {new Date(
                            convertDate(order?.date_added),
                          ).toLocaleDateString('en-US', {
                            weekday: 'short',
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                          })}
                        </div>
                      ) : cancelOrder && !cancelOrderDate ? (
                        <div className="banner-text sidebar-banner ">
                          Order Canceled via Order Portal
                        </div>
                      ) : cancelOrder ? (
                        <div className="banner-text sidebar-banner ">
                          Order Canceled via Order Portal on{' '}
                          {new Date(
                            cancelOrderDate?.replaceAll(
                              '-',
                              '/',
                            ),
                          ).toLocaleDateString('en-US', {
                            weekday: 'short',
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                          })}
                        </div>
                      ) : shopifyCancel ? (
                        <div className="banner-text sidebar-banner ">
                          Order Canceled by Admin on{' '}
                          {new Date(
                            convertDate(shopifyCancel),
                          ).toLocaleDateString('en-US', {
                            weekday: 'short',
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                          })}
                        </div>
                      ) : statusBanner ? (
                        <div className="banner-text sidebar-banner yellow-banner">
                          {statusBanner}
                        </div>
                      ) : (
                        ''
                      )} */}
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link"
                          data-toggle="collapse"
                          data-target="#sidebar-collapse"
                          aria-expanded="true"
                          aria-controls="sidebar-collapse"
                        >
                          <span className="cal-logo">
                            {/* <Image
                              className="file-icon-img"
                              alt=""
                              src={CalendarIcon}
                            /> */}
                            <FontAwesomeIcon
                              icon={faCalendarCheck}
                            />
                          </span>
                          <span className="tab-text">
                            Order Status
                          </span>
                        </button>
                      </h5>
                    </div>

                    <div
                      id="sidebar-collapse"
                      className={`${
                        activeMenu
                          ? 'collapse '
                          : 'collapse show'
                      }`}
                      // className="collapse show"
                      aria-labelledby="sidebar-heading"
                      data-parent="#acc-sidebar"
                    >
                      <div className="card-body">
                        <div className="card-body-inner">
                          <OrderStatus
                            success={success}
                            state={state}
                            setdelStatus={setdelStatus}
                          />
                          <div className="optional-text text-gray">
                            &nbsp;
                          </div>
                          <div className="footer-btn mob-hide">
                            <button className="contact-btn bg-brand">
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://dangleit.com/pages/contact-us"
                              >
                                Contact Us
                              </a>
                            </button>
                            <button
                              className={
                                emailedFor === 'Address' ||
                                order.folder_id == 399366 ||
                                success === 'success'
                                  ? 'contact-btn bg-gray'
                                  : order.folder_id == 399341 ||
                                    order.folder_id === 399369
                                  ? 'contact-btn bg-red'
                                  : 'contact-btn bg-gray'
                              }
                              disabled={order.folder_id == "399346" || order.folder_id == "399366"}
                              onClick={() =>
                                setModalCancel(!modalCancel)
                              }
                            >
                              Cancel Order
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sidebar-bottom-link mob-hide">
                  <ul>
                    <li>
                      <Link href="https://dangleit.com/">
                        <a>View Main Site</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="https://dangleit.com/pages/privacy-policy">
                        <a>View Store Policy</a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8">
                <div id="accordion" className="accordian-box">
                  <div className="card">
                    <div
                      className="card-header"
                      id="headingOne"
                      onClick={() =>
                        collapseSection('collapseOne')
                      }
                    >
                      {/* <div className="banner-text">
                      Action Required
                    </div> */}
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <span className="tab-icon">
                            {/* <Image
                              className="file-icon-img"
                              alt=""
                              src={FileIcon}
                            /> */}
                            <FontAwesomeIcon
                              icon={faFileLines}
                            />
                          </span>
                          <span className="tab-text">
                            Order Summary
                          </span>
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse "
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <div className="card-body-inner">
                          <OrderDetails />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      order?.folder_id === 399341 ||
                      order?.folder_id === 399370 ||
                      emailedFor == 'Photo'
                        ? 'card '
                        : 'card disabled'
                    }
                  >
                    <div
                      className="card-header"
                      id="headingTwo"
                      onClick={() =>
                        collapseSection('collapseTwo')
                      }
                    >
                      {emailedFor == 'Photo' ? (
                        <div className="banner-text">
                          Action Required
                        </div>
                      ) : flagVal !== undefined ? (
                        <div className="banner-text">
                          {flagVal}
                        </div>
                      ) : order.folder_id ==
                        '435734' ? null : null}

                      {order?.folder_id === 399341 ||
                      order?.folder_id === 399370 ||
                      (order?.folder_id === 399369 &&
                        emailedFor == 'Photo') ? (
                        ''
                      ) : (
                        <div className="banner-text">
                          Edit Window Closed (already with
                          designers)
                        </div>
                      )}

                      {order?.folder_id === 399342 ||
                      order?.folder_id === 399343 ||
                      order?.folder_id === 399345 ||
                      order?.folder_id === 399346 ||
                      order?.folder_id === 399347 ? (
                        <div className="banner-text">
                          Edit Window Closed (already with
                          designers)
                        </div>
                      ) : (
                        ''
                      )}

                      {order?.folder_id === 433467 ||
                      order?.folder_id === 433468 ||
                      order?.folder_id === 433469 ||
                      order?.folder_id === 433470 ||
                      order?.folder_id === 433471 ||
                      order?.folder_id === 399348 ||
                      emailedFor == 'Address' ? (
                        <div className="banner-text">
                          Edit Window Closed (already in
                          production)
                        </div>
                      ) : (
                        ''
                      )}

                      {order?.folder_id === 435748 ||
                      order?.folder_id === 435747 ||
                      order?.folder_id === 435746 ||
                      order?.folder_id === 435745 ||
                      order?.folder_id === 435744 ||
                      order?.folder_id === 435755 ||
                      order?.folder_id === 435743 ? (
                        <div className="banner-text">
                          Edit Window Closed (already prepared)
                        </div>
                      ) : (
                        ''
                      )}

                      {order?.folder_id === 435756 ? (
                        <div className="banner-text">
                          Edit Window Closed (already prepared)
                        </div>
                      ) : (
                        ''
                      )}

                      {order?.folder_id === 435735 ? (
                        <div className="banner-text">
                          Edit Window Closed (already delieverd)
                        </div>
                      ) : (
                        ''
                      )}

                      {order?.folder_id === 435757 ? (
                        <div className="banner-text">
                          Edit Window Closed (already prepared)
                        </div>
                      ) : (
                        ''
                      )}

                      {order?.folder_id === 435733 ? (
                        <div className="banner-text">
                          Edit Window Closed (already shipped)
                        </div>
                      ) : (
                        ''
                      )}

                      {order?.folder_id === 399366 ? (
                        <div className="banner-text">
                          Edit Window Closed (already canceled)
                        </div>
                      ) : (
                        ''
                      )}

                      {delStatus?.status == 'delivered' ? (
                        <div className="banner-text">
                          Edit Window Closed (already delivered)
                        </div>
                      ) : (
                        ''
                      )}

                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          onClick={toggleItem}
                        >
                          <span className="tab-icon">
                            <span className="disable-icon">
                              <FontAwesomeIcon icon={faList} />
                            </span>
                            <span className="enable-icon">
                              <FontAwesomeIcon icon={faList} />
                            </span>
                          </span>
                          <span className="tab-text">
                            {/* {itemValue} */}
                            Edit Items
                          </span>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <div className="card-body-inner">
                          <UpdateItems
                            state={state}
                            setStorageState={setStorageState}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      order?.folder_id === 399370 ||
                      order?.folder_id === 399341 ||
                      order?.folder_id === 399342 ||
                      order?.folder_id === 399343 ||
                      order?.folder_id === 399344 ||
                      order?.folder_id === 399345 ||
                      order?.folder_id === 399346 ||
                      order?.folder_id === 399347 ||
                      order?.folder_id === 399348 ||
                      order?.folder_id === 433467 ||
                      order?.folder_id === 433468 ||
                      order?.folder_id === 433469 ||
                      order?.folder_id === 125197 ||
                      order?.folder_id === 433470 ||
                      order?.folder_id === 433471 ||
                      (order?.folder_id === 399369 &&
                        emailedFor == 'Photo') ||
                      (order?.folder_id === 399369 &&
                        emailedFor == 'Address')
                        ? 'card'
                        : 'card disabled'
                    }
                  >
                    <div
                      className="card-header"
                      id="headingThree"
                      onClick={() =>
                        collapseSection('collapseThree')
                      }
                    >
                      {emailedFor == 'Address' ? (
                        <div className="banner-text">
                          Action Required
                        </div>
                      ) : order.folder_id ==
                        '435734' ? null : null}
                      {order?.folder_id === 399370 ||
                      order?.folder_id === 399341 ||
                      order?.folder_id === 399342 ||
                      order?.folder_id === 399343 ||
                      order?.folder_id === 399344 ||
                      order?.folder_id === 399345 ||
                      order?.folder_id === 399346 ||
                      order?.folder_id === 399347 ||
                      order?.folder_id === 399348 ||
                      order?.folder_id === 433467 ||
                      order?.folder_id === 433468 ||
                      order?.folder_id === 433469 ||
                      order?.folder_id === 125197 ||
                      order?.folder_id === 433470 ||
                      order?.folder_id === 433471 ||
                      (order?.folder_id === 399369 &&
                        emailedFor == 'Photo') ||
                      (order?.folder_id === 399369 &&
                        emailedFor == 'Address') ? (
                        ''
                      ) : (
                        <div className="banner-text">
                          Edit Window Closed (already in
                          production)
                        </div>
                      )}

                      {order?.folder_id === 435748 ||
                      order?.folder_id === 435747 ||
                      order?.folder_id === 435746 ||
                      order?.folder_id === 435745 ||
                      order?.folder_id === 435744 ||
                      order?.folder_id === 435755 ||
                      order?.folder_id === 435743 ? (
                        <div className="banner-text">
                          Edit Window Closed (already prepared)
                        </div>
                      ) : (
                        ''
                      )}

                      {order?.folder_id === 435756 ? (
                        <div className="banner-text">
                          Edit Window Closed (already prepared)
                        </div>
                      ) : (
                        ''
                      )}

                      {order?.folder_id === 435735 ? (
                        <div className="banner-text">
                          Edit Window Closed (already delieverd)
                        </div>
                      ) : (
                        ''
                      )}

                      {order?.folder_id === 435757 ? (
                        <div className="banner-text">
                          Edit Window Closed (already prepared)
                        </div>
                      ) : (
                        ''
                      )}

                      {order?.folder_id === 435733 ? (
                        <div className="banner-text">
                          Edit Window Closed (already shipped)
                        </div>
                      ) : (
                        ''
                      )}

                      {delStatus?.status == 'delivered' ? (
                        <div className="banner-text">
                          Edit Window Closed (already delivered)
                        </div>
                      ) : (
                        ''
                      )}

                      {order?.folder_id === 399366 ? (
                        <div className="banner-text">
                          Edit Window Closed (already canceled)
                        </div>
                      ) : (
                        ''
                      )}

                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={toggleAddress}
                        >
                          <span className="tab-icon">
                            <span className="disable-icon">
                              {/* <Image alt="" src={HomeDisIcon} /> */}

                              <FontAwesomeIcon
                                icon={faHouseChimney}
                              />
                            </span>
                            <span className="enable-icon">
                              <FontAwesomeIcon
                                icon={faHouseChimney}
                              />
                              {/* <Image alt="" src={HomeIcon} /> */}
                            </span>
                          </span>
                          <span className="tab-text">
                            {/* {addressValue} */}
                            Edit Address
                          </span>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <div className="card-body-inner">
                          <UpdateAddress
                            state={state}
                            setStorageState={setStorageState}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      // order.folder_id == '435734' ||
                      // order.folder_id == '435735' ||
                      // order.folder_id == '399369' ||
                      // order.folder_id == '435736' ||
                      // order.folder_id == '435738' ||
                      // order.folder_id == '435737' ||
                      // order.folder_id == '437224' ||
                      // order.folder_id == '399370' ||
                      // order.folder_id == '399341' ||
                      // order.folder_id == '399342' ||
                      // order.folder_id == '399343' ||
                      // order.folder_id == '399344' ||
                      // order.folder_id == '399345' ||
                      // order.folder_id == '399346' ||
                      // order.folder_id == '399347' ||
                      // order.folder_id == '399348' ||
                      // order.folder_id == '433467' ||
                      // order.folder_id == '433468' ||
                      // order.folder_id == '433469' ||
                      // order.folder_id == '433470' ||
                      // order.folder_id == '433471' ||
                      // order.folder_id == '435743' ||
                      // order.folder_id == '435744' ||
                      // order.folder_id == '435745' ||
                      // order.folder_id == '435746' ||
                      // order.folder_id == '435747' ||
                      // order.folder_id == '435748' ||
                      // order.folder_id == '435755' ||
                      // order.folder_id == '435756' ||
                      // order.folder_id == '435757' ||
                      // order.folder_id == '435733'

                      order.folder_id === 435734 ||
                      refundStatus ||
                      order.folder_id === 435735
                        ? 'card disabled'
                        : (delStatus?.status !== 'delivered' &&
                            diffDaysForTracking > 5) ||
                          (delStatus?.status === 'delivered' &&
                            diffDaysForDelivery <= 7) ||
                          new Date(extended).getTime() >
                            new Date().getTime()
                        ? 'card '
                        : 'card disabled'
                    }
                  >
                    <div
                      className="card-header"
                      id="headingFour"
                      onClick={() =>
                        collapseSection('collapseFour')
                      }
                    >
                      {/* {order_shipments?.length === 0 && (
                        <div className="banner-text colorGray">
                          Request Window Closed (not shipped)
                        </div>
                      )} */}

                      {/* {refundResponse && (
                        <div className="banner-text colorGreen">
                          Request Approved (20% refund issued)
                        </div>
                      )} */}
                      {/* 
                      {refundStatus && (
                        <div className="banner-text colorYellow">
                          Request Pending (can take up to 72
                          hours)
                        </div>
                      )}

                      {order.folder_id === 435734 && (
                        <div className="banner-text colorYellow">
                          Request Pending (can take up to 72
                          hours)
                        </div>
                      )} */}

                      {/* {order.folder_id === 435735 && (
                        <div className="banner-text colorGreen">
                          Request Approved (replacement order #{' '}
                          {order.source_id} ) */}
                      {/* {console.log(
                            order.source_id,
                            'ordersource_id',
                          )} */}
                      {/* </div>
                      )} */}

                      {/* {diffDaysForShipping < 10 &&
                        delStatus?.status !== 'delivered' && (
                          <div className="banner-text colorGray">
                            Request Window Closed (
                            {diffDaysForShipping} days ago)
                          </div>
                        )}

                      {diffDaysForDelivery > 7 && (
                        <div className="banner-text colorGray">
                          Request Window Closed (delivered 7+
                          days ago)
                        </div>
                      )}

                      {diffDaysForDelivery > 10 && (
                        <div className="banner-text colorGray">
                          Request Window Closed (delivered 7+
                          days ago)
                        </div>
                      )}

                      {extended && ''}

                      {fullyRefundResponse > 0 && (
                        <div className="banner-text colorGreen">
                          Request Approved (100% refund issued)
                        </div>
                      )} */}

                      {
                        // match ? (
                        //   <div className="banner-text colorGreen">
                        //     Request Approved (see email for
                        //     replacement order details)
                        //   </div>
                        // ) :
                        fullyRefundResponse > 0 ? (
                          <div className="banner-text colorGreen">
                            Request Approved ($
                            {parseFloat(
                              fullyRefundResponse,
                            ).toFixed(2)}{' '}
                            refund issued)
                          </div>
                        ) : refundResponse > 0 ? (
                          <div className="banner-text colorGreen">
                            Request Approved ($
                            {parseFloat(refundResponse).toFixed(
                              2,
                            )}{' '}
                            refund issued)
                          </div>
                        ) : order.folder_id === 435735 ? (
                          <div className="banner-text colorGreen">
                            Request Approved (see email for
                            replacement order details)
                          </div>
                        ) : // isReturnable > 0
                        order.folder_id === 435734 ||
                          refundStatus ? (
                          <div className="banner-text colorYellow">
                            Request Pending (can take up to 72
                            hours)
                          </div>
                        ) : new Date(extended).getTime() >
                          new Date().getTime() ? (
                          ''
                        ) : diffDaysForTracking <= 5 &&
                          delStatus?.status != 'delivered' ? (
                          <div className="banner-text colorGray">
                            Request Window Closed (tracking
                            updated {'<'}5 days ago)
                          </div>
                        ) : diffDaysForDelivery > 7 &&
                          delStatus?.status == 'delivered' ? (
                          <div className="banner-text colorGray">
                            Request Window Closed (delivered{' '}
                            {'>'}7 days ago)
                          </div>
                        ) : // : diffDaysForShipping >= 7 &&
                        //   delStatus?.status !== 'delivered' ? (
                        //   <div className="banner-text colorGray">
                        //     {/* Request Window Closed ( shipped{' '}
                        //     {diffDaysForShipping} days ago) */}
                        //     Request Window Closed ( delivered {'>'}7 days ago)
                        //   </div>
                        // )
                        order_shipments?.length === 0 ? (
                          <div className="banner-text colorGray">
                            Request Window Closed (not yet
                            shipped)
                          </div>
                        ) : (
                          ''
                        )
                      }

                      <h5 className="mb-0">
                        <button
                          className="btn btn-link"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="true"
                          aria-controls="collapseFour"
                        >
                          <span className="tab-icon">
                            <span className="tab-icon">
                              <span className="disable-icon">
                                <FontAwesomeIcon
                                  icon={faRightLeft}
                                />
                              </span>
                              <span className="enable-icon">
                                <FontAwesomeIcon
                                  icon={faRightLeft}
                                />
                              </span>
                            </span>
                          </span>
                          <span className="tab-text">
                            Request Replacement/Refund
                          </span>
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapseFour"
                      className={` ${'collapse'} 
                      ${refundStatus && 'collapse'} `}
                      aria-labelledby="headingFour"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <div className="card-body-inner">
                          <Refund
                            delStatus={delStatus}
                            setrefundStatus={setrefundStatus}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      className="card-header"
                      id="headingFive"
                      onClick={() =>
                        collapseSection('collapseFive')
                      }
                    >
                      {/* <div className="banner-text">
                      Action Required
                    </div> */}
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-expanded="true"
                          aria-controls="collapseFive"
                          onClick={() => setShQuantity(true)}
                        >
                          <span className="tab-icon">
                            <span className="tab-icon">
                              <span className="disable-icon">
                                <FontAwesomeIcon
                                  icon={faCartShopping}
                                />
                              </span>
                              <span className="enable-icon">
                                <FontAwesomeIcon
                                  icon={faCartShopping}
                                />
                                {/* <Image alt="" src={OrderIcon} /> */}
                              </span>
                            </span>
                          </span>
                          <span className="tab-text">
                            Reorder
                          </span>
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapseFive"
                      className="collapse"
                      aria-labelledby="headingFive"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <div className="card-body-inner">
                          <Reorder shQuantity={shQuantity} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <main>{children}</main> */}
              </div>
              <div className="col-lg-12">
                <div className="footer-btn mob-show desktop-hide wd-100">
                  <button className="contact-btn bg-brand">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://dangleit.com/pages/contact-us"
                    >
                      Contact Us
                    </a>
                  </button>
                  <button
                    className={
                      order.folder_id == 399366 ||
                      success === 'success'
                        ? 'contact-btn bg-gray'
                        : order.folder_id == 399341 ||
                          order.folder_id === 399369 ||
                          emailedFor === 'Photo'
                        ? 'contact-btn bg-red'
                        : 'contact-btn bg-gray'
                    }
                    disabled={order.folder_id == "399346" || order.folder_id == "399366"}
                    onClick={() => setModalCancel(!modalCancel)}
                  >
                    Cancel Order
                  </button>
                </div>
              </div>
              <div className="col-lg-12 mob-show wd-100">
                <div className="sidebar-bottom-link">
                  <ul>
                    <li>
                      <Link href="https://dangleit.com/">
                        <a>View Main Site</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="https://dangleit.com/pages/privacy-policy">
                        <a>View Store Policy</a>
                      </Link>
                    </li>
                    <li>
                      <button onClick={() => logoutUser()}>
                        Log Out
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div tw="min-h-screen flex  bg-gray-50">
        {query?.id && (
          <SidebarNavMobile
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
        )}

        <SidebarNavDesktop />
        <div tw="flex flex-col w-0 flex-1 overflow-y-auto">
          {query?.id && (
            <div tw="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 flex items-center ">
              <button
                tw="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setSidebarOpen(true)}
              >
                <span tw="sr-only">Open sidebar</span>
                <MenuIcon
                  tw="h-8 w-8 text-brand"
                  aria-hidden="true"
                />
              </button>
              <span tw="mx-auto pr-12 pt-2">
                <Image
                  alt="Dangle It Logo"
                  width="85"
                  height="30"
                  tw="h-8 w-auto text-center inline-block"
                  src="/logo.png"
                  quality={100}
                />
              </span>
            </div>
          )}
          <main tw="flex-1 relative z-0 focus:outline-none">
            <div
              css={[
                tw`px-6 py-6 mx-auto max-w-7xl sm:px-10 md:px-20`,
                !query?.id && tw`flex h-full`,
              ]}
            >
              {children}
            </div>
          </main>
        </div>
      </div> */}
        {/* cancel order modal */}
        <Modal
          toggle={() => setModalCancel(!modalCancel)}
          isOpen={modalCancel}
          className="popup-dialog edit-item-popup cancel-order-popup"
        >
          {' '}
          <div className="p-2">
            <div className="top-header-item">
              <div className="d-flex align-items-center">
                <span className="mr-4 d-flex cart-icon">
                  {/* <Image alt="" src={OrderIcon} /> */}
                  <FontAwesomeIcon icon={faTrashCan} />
                </span>
                <span className="heading">Cancel Order</span>
              </div>
              <span
                aria-hidden={true}
                onClick={() => {
                  setModalCancel(!modalCancel)
                }}
                className="cursor-pointer close-btn"
              >
                <span className="close-icon d-flex cart-icon">
                  <FontAwesomeIcon icon={faXmark} />
                </span>
              </span>
            </div>
          </div>
          <div className="modal-content-box ">
            <CancelOrder
              toggleShow={toggleShow}
              setSuccess={setSuccess}
            />
          </div>
        </Modal>
      </div>
    )
  )
}

export const getLayout = page => <SiteLayout>{page}</SiteLayout>

export default SiteLayout
