import {SyntheticEvent} from 'react'
import {ExclamationCircleIcon} from '@heroicons/react/solid'

interface TextProps {
  type?: string
  name: string
  placeholder?: string
  hasError?: boolean
  onChange?: (event: SyntheticEvent) => void
  value?: string
  defaultValue?: string
  autoComplete?: string
}

const Text: React.FC<TextProps> = ({
  type = 'text',
  name,
  placeholder = '',
  hasError,
  onChange,
  defaultValue,
  value = '',
  autoComplete = '',
}) => {
  return (
    <div className="">
      <input
        type={type}
        name={name}
        id={name}
        tw="shadow-sm text-[#044161] focus:ring-blue-500 focus:border-blue-500 block w-full text-sm border border-gray-200 rounded-md py-2 px-3"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
      />
      {hasError && (
        <div tw="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <ExclamationCircleIcon tw="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
      )}
    </div>
  )
}

export default Text
