import {InMemoryCache, makeVar} from '@apollo/client'

const isSSR = typeof window === 'undefined'

export const lookupStorageKey = 'dangle-it-crm:lookup-state'

export const emailVar = makeVar(
  isSSR ? '' : window.sessionStorage.getItem('email'),
)
export const orderNumVar = makeVar(
  isSSR ? '' : window.sessionStorage.getItem('orderNumber'),
)

export const orderVar = makeVar(
  isSSR ? '' : window.sessionStorage.getItem('order'),
)

export const timestampVar = makeVar(
  isSSR ? '' : window.sessionStorage.getItem('timestamp'),
)

export const cache = new InMemoryCache({
  typePolicies: {
    Order: {
      keyFields: ['source_id'],
    },
    Query: {
      fields: {
        orderNumber: {
          read() {
            return orderNumVar()
          },
        },
        email: {
          read() {
            return emailVar()
          },
        },
        order: {
          read() {
            return orderVar()
          },
        },
        timestamp: {
          read() {
            return timestampVar()
          },
        },
      },
    },
  },
})

export const initialState = {
  authenticated: false,
  orderNumber: '',
  email: '',
  order: '',
  timestamp: 0,
}

// how often to refresh the order
export const offset = 0.5 * 60 * 1000
