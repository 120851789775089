import {getLayout as getSiteLayout} from './SiteLayout'
import {useSessionStorageState} from '../utils/useSessionStorageState'
import {initialState, lookupStorageKey} from '../apollo/cache'
import {useRouter} from 'next/router'

const OrderLayout = ({children}) => {
  const router = useRouter()
  const [state] = useSessionStorageState(
    lookupStorageKey,
    initialState,
  )
  const {order, orderNumber} = state

  // if the fetched order doesn't match the order in the URL, something is wrong...
  if (
    router?.query?.id &&
    router?.query?.id !== orderNumber &&
    typeof window !== 'undefined'
  ) {
    // console.log('Redirecting: ', router.query.id, orderNumber)
    router.push('/')
  }

  const title =
    children.props?.title ||
    `Order #${orderNumber}` ||
    'dangle it crm' // router.query.id
  const subtitle = order.date_added
    ? new Date(
        order.date_added.replace(/-/g, '/'),
      ).toLocaleString('en-US', {
        month: 'long',
        day: '2-digit',
        year: 'numeric',
      })
    : ''

  return (
    <>
      <header tw="px-6">
        <h1
          tw="w-full text-4xl text-brand font-bold"
          suppressHydrationWarning
        >
          {title}
        </h1>
        <p tw="text-gray-400" suppressHydrationWarning>
          {!!subtitle && `Placed on ${subtitle}`}
        </p>
      </header>
      {children}
    </>
  )
}

export const getLayout = page =>
  getSiteLayout(<OrderLayout>{page}</OrderLayout>)

export default OrderLayout
