// import {gql, useMutation} from '@apollo/client'
// import {useState} from 'react'
// import Button from './Button'
// import {useSessionStorageState} from '../utils/useSessionStorageState'
// import {Loading} from './Loading'
// import {CheckIcon, XIcon, SparklesIcon} from '@heroicons/react/outline'
import {scents} from '../utils/helpers'
import Client from 'shopify-buy'
import {useState, useEffect} from 'react'
import {useQuery} from '@apollo/client'
import {PortalOrderByIdQuery} from '../apollo/queries'

const shopifyClient = Client.buildClient({
  domain: 'whifffresheners.myshopify.com', //`${process.env.SHOPIFY_STORE}.myshopify.com`,
  storefrontAccessToken: '8d8be545cfd02316b7b9480d71eb14ed', //process.env.SHOPIFY_STOREFRONT_TOKEN,
})
// console.log('shopi', shopifyClient)

type Props = {
  replacementError?: (scent: string) => void
  setFirst?: (scent: string) => void
  currentScent: string
  setNewScent?: (scent: string) => void
  type?: string
}

const ScentAction: React.FC<Props> = ({
  replacementError,
  setFirst,
  currentScent,
  setNewScent,
  type = 'new',
}) => {
  const [newScent, setnewScent] = useState([])
  const [scentnew, setscentnew] = useState(null)
  const [ScentItem, setScentItem] = useState()

  const filteredScent = newScent.filter(
    sc => sc.value != 'Select A Scent',
  )

  // console.log(filteredScent)

  // console.log(filteredScent.indexOf(currentScent))

  async function buyIt() {
    // setLoading(true)
    // setStatus('pending')
    // console.log('shopifyClient.product', shopifyClient.product)
    return await shopifyClient.product
      .fetchAll()
      .then(products => {
        // console.log('shopifyClient', shopifyClient)

        // console.log('products', products)
        return products.find(item => {
          item.title === 'Custom Air Freshener',
            setnewScent(item.options[0].values)
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
  useEffect(() => {
    buyIt()
  }, [])

  useEffect(() => {
    let x = filteredScent.find(el => el.value === currentScent)
    if (x) {
      setscentnew(x)
      setFirst(x)
    }
  }, [filteredScent])

  return (
    <div tw="">
      {/* <h3 tw="text-lg text-brand ">{type} Scent</h3> */}
      <h3 tw="text-lg text-brand "> Scent</h3>
      <div tw="flex-shrink-0 font-normal text-gray-500">
        <div className="select-box">
          <span className="select-icon">
            <i className="fa fa-caret-down"></i>
          </span>
          <select
            value={!scentnew ? 'Select A Scents' : currentScent}
            onChange={e => setNewScent(e.currentTarget.value)}
            tw="h-full w-full p-2 border border-gray-400 bg-white text-gray-500 focus:ring-brand rounded-md"
          >
            {!scentnew && <option>Select A Scent</option>}
            {/* {scents.map(scent => (
            <option key={scent.value} value={scent.value}>
              {scent.label}
            </option>
          ))} */}
            {filteredScent.map(el => {
              // console.log(el)
              return (
                <>
                  <option key={el.value} value={el.value}>
                    {el.value}
                  </option>
                </>
              )
            })}
          </select>
        </div>
        {!scentnew && (
          <span style={{color: 'red'}}>{replacementError}</span>
        )}
      </div>
    </div>
  )
}

export default ScentAction
