import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCircleCheck, faTruck, faHouseChimney } from '@fortawesome/free-solid-svg-icons';

const OrderHeader = (props) => {
  const { order } = props;

  const [minimumShipDate, setMinimumShipDate] = useState(null);
  const [maximumShipDate, setMaximumShipDate] = useState(null);
  const [minimumDeliveryDate, setMinimumDeliveryDate] = useState(null);
  const [maximumDeliveryDate, setMaximumDeliveryDate] = useState(null);

  useEffect(() => {
    const fetchDeliveryDates = async () => {
      try {
        if (order?.date_added) {
          // Format order date to `YYYY-MM-DD HH:MM:SS`
          const estDateAdded = order?.date_added ? convertToEST(order.date_added) : null;
          const formattedOrderDate = estDateAdded; //estDateAdded.split(' ')[0];
          
          // Fetch delivery dates via the API
          const response = await fetch('/api/deliveryDates', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ order_date: formattedOrderDate }),
          });

          if (response.ok) {
            const deliveryDates = await response.json();
            setMinimumShipDate(deliveryDates.minimumShipDate);
            setMaximumShipDate(deliveryDates.maximumShipDate);
            setMinimumDeliveryDate(deliveryDates.minimumDeliveryDate);
            setMaximumDeliveryDate(deliveryDates.maximumDeliveryDate);
          } else {
            console.error('Error fetching delivery dates:', await response.json());
          }
        }
      } catch (error) {
        console.error('Error fetching delivery dates:', error);
      }
    };

    fetchDeliveryDates();
  }, [order?.date_added]);

  // Manual date formatting function without relying on Date object
  const formatDate = (date, withYear = true) => {
    if (!date) return 'N/A';

    // Extract year, month, and day from the date string (assumed format: YYYY-MM-DD)
    const [year, month, day] = date.split('-').map(Number);

    // Define array of abbreviated month names
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ];

    return withYear ? `${months[month - 1]} ${day}, ${year}` : `${months[month - 1]} ${day}`;
  };

  const convertToEST = (dateAdded: string): string => {
    // Parse the GMT date string and convert to EST
    const estDate = moment.tz(dateAdded, 'YYYY-MM-DD HH:mm:ss', 'UTC').tz('America/New_York');
    return estDate.format('YYYY-MM-DD HH:mm:ss');
  };

  return (
    <>
      <div className="order-header">
        <div className="container text-blue order-header-container">
          <div className="row align-item">
            <div className="col-lg-4">
              <h1 className="main-heading text-blue mob-hide">
                Order #{props.orderID}
              </h1>
            </div>
            <div className="col-lg-8">
              <div className="order-date-box">
                <div className="order-date">
                  <FontAwesomeIcon
                    className="order-header-icon"
                    icon={faCircleCheck}
                  />
                  <div className="order-date-inner">
                    <p className="text-blue order-subtitle">Order Date</p>
                    <h5 className="bold text-blue f-22">
                      {formatDate(convertToEST(order?.date_added).split(' ')[0], true)}
                    </h5>
                  </div>
                </div>
                <div className="order-date-arrow first-order-date-arrow">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
                <div className="ship-date">
                  <div>
                    <FontAwesomeIcon
                      className="order-header-icon big-icon"
                      icon={faTruck}
                    />
                  </div>
                  <div className="ship-date-inner">
                    <p className="text-blue order-subtitle">Est. Ship Date</p>
                    <div className="ship-date-range">
                      <h5 className="bold text-blue f-22">
                        {formatDate(minimumShipDate, false)}
                      </h5>
                      <div className="seperator bold text-blue f-22">-</div>
                      <h5 className="bold text-blue f-22">
                        {formatDate(maximumShipDate, false)}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="order-date-arrow">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
                <div className="delivery-date">
                  <div>
                    <FontAwesomeIcon
                      className="order-header-icon"
                      icon={faHouseChimney}
                    />
                  </div>
                  <div className="delivery-date-inner">
                    <p className="text-blue order-subtitle">Est. Delivery Date</p>
                    <div className="ship-date-range">
                      <h5 className="bold text-blue f-22">
                        {formatDate(minimumDeliveryDate, false)}
                      </h5>
                      <div className="seperator bold text-blue f-22">-</div>
                      <h5 className="bold text-blue f-22">
                        {formatDate(maximumDeliveryDate, false)}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderHeader;
