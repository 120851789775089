import React from 'react'
import {ApolloProvider} from '@apollo/client'
import Head from 'next/head'
import {GlobalStyles} from 'twin.macro'
import {useApollo} from '../apollo/client'
import Banner from '../components/Banner'
import SiteLayout from '../components/SiteLayout'
import {useRouter} from 'next/router'
import '../styles/globals.css'
const App = ({Component, pageProps}) => {
  const router = useRouter()
  const apolloClient = useApollo(pageProps)
  const getLayout =
    Component.getLayout ||
    (page => <SiteLayout>{page}</SiteLayout>)

  return (
    <>
      <Head>
        <title>Dangle It Order Lookup</title>
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
        />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css"
        ></link>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/js/bootstrap.min.js"></script>
      </Head>
      <GlobalStyles />
      <ApolloProvider client={apolloClient}>
        {router.asPath !== '/' && <Banner />}
        {getLayout(<Component {...pageProps} />)}
      </ApolloProvider>
    </>
  )
}

export default App
