const useOrderItem = item => {
  if (!item || !item.variation_list) return null

  // const image = item.variation_list.find(
  //   v => v.key === '_Uploaded Image (direct link)',
  // ).value
  const scent = item.variation_list.find(v => v.key === 'Scent')?.value
  const crop = item.variation_list.find(v => v.key === 'Crop')?.value
  // const shopifyId = item.metadata.find(v => v.key === 'shopify_item_id').value
  const image = item.metadata.find(v => v.key === 'image')?.value
  const flag = item.variation_list.find(v => v.key === 'Flag')?.value
  // const variantId = item.metadata.find(
  //   v => v.key === 'shopify_variant_id' || v.key === 'ShopifyVariantID',
  // ).value

  return {image, scent, crop, flag}
}

export default useOrderItem
