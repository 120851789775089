import * as React from 'react'
const isSSR = typeof window === 'undefined'

function useSessionStorageState(
  key,
  defaultValue = {},
  {serialize = JSON.stringify, deserialize = JSON.parse} = {},
) {
  const [state, setState] = React.useState(() => {
    const valueInSessionStorage = isSSR
      ? ''
      : window.localStorage.getItem(key)
    if (valueInSessionStorage) {
      return deserialize(valueInSessionStorage)
    }
    return typeof defaultValue === 'function'
      ? defaultValue()
      : defaultValue
  })

  const prevKeyRef = React.useRef(key)

  React.useEffect(() => {
    const prevKey = prevKeyRef.current
    if (prevKey !== key && !isSSR) {
      window.localStorage.removeItem(prevKey)
    }
    prevKeyRef.current = key
    !isSSR && window.localStorage.setItem(key, serialize(state))
  }, [key, state, serialize])

  return [state, setState]
}

export {useSessionStorageState}
