import {Price} from './Price'

export const OrderSummary = ({order}) => {
  const {
    discount_total,
    order_total,
    tax_total,
    shipping_total,
    product_total,
    refund_total,
    // handling_total,
  } = order

  // console.log(
  //   discount_total,
  //   order_total,
  //   tax_total,
  //   shipping_total,
  //   product_total,
  //   refund_total,
  //   '$$',
  // )

  return (
    <div tw="text-gray-400 mt-2 " className="mrt-0">
      {product_total != 0 ? (
        <p tw="flex justify-between">
          <span>Subtotal</span>
          <Price>${product_total.toFixed(2)}</Price>
        </p>
      ) : (
        <p tw="flex justify-between">
          <span>Subtotal</span>
          <Price>$0.00</Price>
        </p>
      )}
      {discount_total != 0 ? (
        <p tw="flex justify-between">
          <span>Discount</span>
          <Price>${discount_total.toFixed(2)}</Price>
        </p>
      ) : (
        <p tw="flex justify-between">
          <span>Discount</span>
          <Price>$0.00</Price>
        </p>
      )}
      {shipping_total != 0 ? (
        <p tw="flex justify-between">
          <span>Shipping</span>
          <Price>${shipping_total.toFixed(2)}</Price>
        </p>
      ) : (
        <p tw="flex justify-between">
          <span>Shipping</span>
          <Price>$0.00</Price>
        </p>
      )}
      {tax_total != 0 ? (
        <p tw="flex justify-between">
          <span>Taxes</span>
          <Price>${tax_total.toFixed(2)}</Price>
        </p>
      ) : (
        <p tw="flex justify-between">
          <span>Taxes</span>
          <Price>$0.00</Price>
        </p>
      )}      
      {/* <p tw="flex justify-between">
        <span>Tip</span>
        <Price>${handling_total}</Price>
      </p> */}
      
      {refund_total !== 0 ? (
        <p tw="flex justify-between">
          <span>Refund</span>
          <Price>-${refund_total.toFixed(2)}</Price>
        </p>
      ) : (
        ''
      )}
      <p tw="flex justify-between font-semibold">
        <span className="text-brand">Total</span>
        <Price>${order_total.toFixed(2)}</Price>
      </p>
    </div>
  )
}
