import {useLazyQuery, gql, useMutation} from '@apollo/client'
import {useEffect, useState} from 'react'
import {initialState, lookupStorageKey} from '../apollo/cache'
import {ORDER_FIELDS} from '../apollo/fragments'
import {useSessionStorageState} from '../utils/useSessionStorageState'

export const CHECK_STATUS = gql`
  query DeliveryStatus(
    $carrier: String!
    $tracking_number: String!
  ) {
    deliveryStatus(
      carrier: $carrier
      tracking_number: $tracking_number
    ) {
      status
      attempt
      last_detail {
        datetime
        status
        message
      }
    }
  }
`

const UPDATE_DELIVERY_STATUS = gql`
  ${ORDER_FIELDS}
  mutation DeliveryStatus($orderId: String!, $status: String!) {
    updateDeliveryStatus(orderId: $orderId, status: $status) {
      status
      message
      order {
        ...OrderFields
      }
    }
  }
`

const useDeliveryStatus = orderId => {
  // console.log(orderId, 'orderId')
  const [state, setState] = useSessionStorageState(
    lookupStorageKey,
    initialState,
  )
  const [deliveryData, setDeliveryData] = useState(null)
  const [returnable, setReturnable] = useState(false)
  const [isSetStatus, setIsSetStatus] = useState(false)

  const [checkStatus, {loading, data, called}] = useLazyQuery(
    CHECK_STATUS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted(data) {
        setDeliveryData({...data.deliveryStatus})
        updateDeliveryStatus({
          variables: {
            orderId: orderId,
            status: JSON.stringify({...data.deliveryStatus}),
          },
        })
        setIsSetStatus(true)
      },
      onError(error) {
        console.log('Done Error? ', error)
      },
    },
  )

  const [updateDeliveryStatus, {data: updateData}] = useMutation(
    UPDATE_DELIVERY_STATUS,
    {
      onCompleted(data) {
        data.updateDeliveryStatus.order &&
          setState({
            ...state,
            order: data.updateDeliveryStatus.order,
            timestamp: Date.now(),
          })
      },
    },
  )

  // need to check Order Meta for a delivery status
  // that we stash in the order upon successful EasyPost query

  function checkDeliveryStatus(shipment, metadata) {
    if (!shipment || !shipment.tracking_number) return null
    const {carrier_code, tracking_number} = shipment
    let delivery_status = metadata?.find(
      v => v.key === 'delivery_status',
    )?.value
    if (delivery_status) {
      delivery_status = JSON.parse(delivery_status)
    }
    if (!called) {
      checkStatus({
        variables: {carrier: carrier_code, tracking_number},
      })
    }
    if (
      !isSetStatus &&
      delivery_status?.status === 'delivered'
    ) {
      setIsSetStatus(true)
      setDeliveryData(delivery_status)
    }
  }

  function isReturnable(deliveryDate, range = 8) {
    // console.log('Is Returnable: ', deliveryDate)
    if (!deliveryDate) setReturnable(false)
    const date1 = new Date(deliveryDate)
    const date2 = new Date()
    const timeDiff = Math.abs(date2.getTime() - date1.getTime())
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
    // console.log('In Range? ', diffDays <= range)
    setReturnable(diffDays <= range)
  }

  useEffect(() => {
    if (!deliveryData) return
    const {status, last_detail} = deliveryData
    isReturnable(last_detail?.datetime)
    return () => {}
  }, [deliveryData])

  // useEffect(() => {
  //   console.log('UE Returnable? ', returnable)
  // }, [returnable])

  return {
    checkDeliveryStatus,
    status: deliveryData,
    returnable,
  }
}

export default useDeliveryStatus
