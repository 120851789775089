import {getLayout} from '../../../components/OrderLayout'
import {
  initialState,
  lookupStorageKey,
} from '../../../apollo/cache'
import {useSessionStorageState} from '../../../utils/useSessionStorageState'
import React, {useState} from 'react'
import {useRouter} from 'next/dist/client/router'
import {
  CheckCircleIcon,
  CheckIcon,
  ChevronRightIcon,
  XIcon,
} from '@heroicons/react/outline'
import {gql, useMutation} from '@apollo/client'
import {Loading} from '../../../components/Loading'
import {actions} from '../../../components/OrderActions'
import UnavailableAction from '../../../components/UnavailableAction'
import ShadowBox from '../../../components/layout/ShadowBox'
import tw from 'twin.macro'
import Button from '../../../components/Button'
import Subtitle from '../../../components/headings/Subtitle'
import H2 from '../../../components/headings/H2'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

export const CANCEL_ORDER = gql`
  mutation CancelOrder(
    $orderId: String!
    $folderId: Int!
    $options: CancelOrderOptions
  ) {
    cancelOrder(
      orderId: $orderId
      folderId: $folderId
      options: $options
    ) {
      status
      message
      order {
        folder_id
      }
    }
  }
`

const UpdateItems = ({toggleShow, setSuccess}) => {
  const router = useRouter()
  const [status, setStatus] = useState('idle') // idle, pending, resolved, rejected
  // console.log(status, 'status')
  const [userAction, setUserAction] = useState(null)
  const [state] = useSessionStorageState(
    lookupStorageKey,
    initialState,
  )

  const [cancelOrder, {loading, error, data}] = useMutation(
    CANCEL_ORDER,
    {
      onCompleted: data => {
        if (data.cancelOrder.status === 'success') {
          setSuccess(data.cancelOrder.status)
          setStatus('resolved')
          router.push(`/order/${state.orderNumber}/success`)
        } else {
          setStatus('rejected')
        }
      },
    },
  )

  const {order} = state
  const {folder_id} = order

  if (!state.order) return null

  const currentAction = actions.find(
    action => action.id === 'cancel-order',
  )
  if (
    !currentAction ||
    !currentAction.folders.includes(folder_id)
  )
    return <UnavailableAction />

  function confirmHandler() {
    setStatus('pending')
    cancelOrder({
      variables: {
        orderId: order.id,
        folderId: 399366,
        options: {
          metadata: {key: 'Cancel', value: 'CancelOrder'},
        },
      },
    })
  }

  function cancelHandler() {
    // setIsOpen(false)
    router.push(`/order/${state.orderNumber}`)
  }

  return (
    <>
      {/* <ShadowBox> */}
      <div className="item-box px-3">
        {/* <H2>Cancel Order</H2> */}
        <p tw="text-gray-400 text-base text-center">
          Are you sure you would like to cancel your entire
          order?
        </p>
        <button
          css={[
            tw`block w-full hover:bg-gray-100 rounded-md mt-4`,
            userAction && tw`bg-gray-100`,
          ]}
          // onClick={() => setUserAction(true)}
          onClick={() => {
            toastr.options = {
              positionClass: 'toast-top-right',
              hideDuration: 300,
              timeOut: 3000,
            }

            toastr.success(`Canceled`)

            toggleShow()
            confirmHandler()
          }}
        >
          <div tw="px-4 py-4 flex items-center sm:px-6">
            {/* <span tw="w-6 h-6 bg-transparent border border-brand rounded-full relative inline-flex items-center justify-center">
              {userAction && (
                <CheckIcon tw="h-3 w-3 text-brand" />
              )}
            </span> */}
            <div tw="text-center w-full">
              <h3 tw="text-brand text-base font-semibold leading-none md:leading-normal">
                Yes, I&apos;d like to cancel my order
              </h3>
            </div>
          </div>
        </button>
        <button
          css={[
            tw`block w-full hover:bg-gray-100 rounded-md`,
            userAction === false && tw`bg-gray-100`,
          ]}
          // onClick={() => setUserAction(false)}
          onClick={() => {
            toggleShow()
            cancelHandler()
          }}
        >
          <div tw="px-4 py-4 flex items-center sm:px-6">
            {/* <span tw="w-6 h-6 bg-transparent border border-brand rounded-full relative inline-flex items-center justify-center">
              {userAction === false && (
                <CheckIcon tw="h-3 w-3 text-brand" />
              )}
            </span> */}
            <div tw="text-center w-full">
              <h3 tw="text-brand text-base font-semibold leading-none md:leading-normal">
                No, I&apos;d like to keep my order
              </h3>
            </div>
          </div>
        </button>
      </div>
      {/* </ShadowBox> */}

      {/* <div tw="grid place-items-center mx-auto text-center mt-3 mb-2">
        <span onClick={toggleShow}>
          <button
            key="verify-address"
            type="submit"
            className="btn brand-btn btn-width"
            css={[status === 'resolved' && tw`bg-green-600`]}
            onClick={userAction ? confirmHandler : cancelHandler}
            disabled={
              status === 'pending' || status === 'resolved'
            }
          >
            Submit
            {status === 'pending' ? <Loading tw="ml-2" /> : null}
            {status === 'resolved' ? (
              <CheckIcon tw="pl-2 h-5 w-5" />
            ) : null}
          </button>
        </span>
      </div> */}
      {/* <Dialog
        isOpen={isOpen}
        confirm={confirmHandler}
        cancel={cancelHandler}
        loading={loading}
      /> */}
    </>
  )
}

UpdateItems.getLayout = getLayout
UpdateItems.displayName = 'CancelOrder'

export default UpdateItems

// const Alert = () => {
//   return (
//     <div tw="rounded-md bg-green-50 p-4 mb-6">
//       <div tw="flex">
//         <div tw="flex-shrink-0">
//           <CheckCircleIcon tw="h-5 w-5 text-green-400" aria-hidden="true" />
//         </div>
//         <div tw="ml-3">
//           <h3 tw="text-lg font-medium text-green-800">Success</h3>
//           <div tw="mt-2 text-sm text-green-700">
//             <p>
//               Your order was successfully cancelled. If you do not see a full
//               refund in 3-4 business days, please contact customer support.
//             </p>
//           </div>
//           <div tw="mt-2.5 -mb-1.5 -mx-2 flex">
//             <button
//               type="button"
//               tw="bg-green-100 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
//             >
//               Back to start
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// const Dialog = ({isOpen, confirm, cancel, loading}) => {
//   if (!isOpen) return null

//   return (
//     <div
//       tw="fixed z-10 inset-0 overflow-y-auto"
//       aria-labelledby="modal-title"
//       role="dialog"
//       aria-modal="true"
//     >
//       <div tw="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
//         <div
//           tw="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
//           aria-hidden="true"
//         ></div>
//         <span
//           tw="hidden sm:inline-block sm:align-middle sm:h-screen"
//           aria-hidden="true"
//         >
//           &#8203;
//         </span>
//         <div tw="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
//           <div tw="sm:flex sm:items-start">
//             <div tw="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
//               <svg
//                 tw="h-6 w-6 text-red-600"
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//                 aria-hidden="true"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth="2"
//                   d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
//                 />
//               </svg>
//             </div>
//             <div tw="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
//               <h3
//                 tw="text-lg leading-6 font-medium text-gray-900"
//                 id="modal-title"
//               >
//                 Cancel Order
//               </h3>
//               <div tw="mt-2">
//                 <p tw="text-sm text-gray-500">
//                   Are you sure you want to deactivate your account? All of your
//                   data will be permanently removed from our servers forever.
//                   This action cannot be undone.
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div tw="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
//             <button
//               type="button"
//               tw="inline-flex justify-center w-full rounded-md border shadow-sm pl-4 pr-0 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
//               onClick={confirm}
//             >
//               <span tw="mr-4">Confirm Cancel</span>
//               {loading ? <Loading /> : null}
//             </button>
//             <button
//               type="button"
//               tw="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
//               onClick={cancel}
//             >
//               Go Back
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }
