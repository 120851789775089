import React, {useState} from 'react'
import Image from 'next/image'
import Logo from '../public/logo.png'
import PowerOff from '../public/power-off.png'
import Link from 'next/link'
import router from 'next/router'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPowerOff} from '@fortawesome/free-solid-svg-icons'
const MainHeader = props => {
  const logoutUser = () => {
    localStorage.removeItem('dangle-it-crm:lookup-state')
    router.push('/')
  }
  return (
    <div className="container">
      <div className="header_box">
        <div className="brand-logo">
          <Image alt="" src={Logo} />
        </div>
        <div className="mob-show order-id">
          <h1 className="main-heading text-blue">
            Order #{props.orderID}
          </h1>
        </div>
        <div className="logout_btn mob-hide">
          <button onClick={() => logoutUser()}>
            <a>
              <span className="power_logo">
                <FontAwesomeIcon icon={faPowerOff} />
              </span>
              <span className="semi-bold">Log Out</span>
            </a>
          </button>
        </div>
      </div>
    </div>
  )
}

export default MainHeader
