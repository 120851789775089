import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import Image from 'next/image'
import {useDropzone} from 'react-dropzone'
import {getLayout} from '../../../components/OrderLayout'
import {lookupStorageKey} from '../../../apollo/cache'
import {useSessionStorageState} from '../../../utils/useSessionStorageState'
import H2 from '../../../components/headings/H2'
import {OrderItem} from '../../../components/OrderItem'
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CloudUploadIcon,
  UploadIcon,
  XIcon,
} from '@heroicons/react/outline'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faList,
  faRightLeft,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import {actions} from '../../../components/OrderActions'
import UnavailableAction from '../../../components/UnavailableAction'
import ShadowBox from '../../../components/layout/ShadowBox'
import tw from 'twin.macro'
import useBoolean from '../../../hooks/useBoolean'
import ScentAction from '../../../components/ScentAction'
import useOrderItem from '../../../hooks/useOrderItem'
import CropAction from '../../../components/CropAction'
import {gql, useMutation} from '@apollo/client'
import Button from '../../../components/Button'
import {Loading} from '../../../components/Loading'
import Subtitle from '../../../components/headings/Subtitle'
import {ORDER_FIELDS} from '../../../apollo/fragments'
import router from 'next/router'
import axios from 'axios'
import Client from 'shopify-buy'
import {Modal} from 'reactstrap'
import ItemListIcon from '../../../public/item-list.png'
import CloseIcon from '../../../public/close-icon.png'
import useOrderRefresher from '../../../hooks/useOrderRefresher'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import AWS from 'aws-sdk'
import AWSConfig from '../../../aws-config'


const AWS_S3_BUCKET = 'crmdangleit'
const AWS_S3_REGION = 'us-west-2'
const AWS_ACCESS_KEY_SECRET = '8/lTtzWke9g0wmuYYJgnc+aAt0a92rLXyenaVcO4'
const AWS_ACCESS_KEYID = 'AKIA3S3YN4WIRYNCQEEU'

const UPDATE_ORDER_ITEMS = gql`
  ${ORDER_FIELDS}
  mutation UpdateOrderItems(
    $orderId: String!
    $items: [UpdateItem!]!
  ) {
    updateOrderItems(orderId: $orderId, items: $items) {
      status
      message
      execution_time
      order {
        ...OrderFields
      }
    }
  }
`

const validReasons = ['Design']
const shopifyClient = Client.buildClient({
  domain: 'whifffresheners.myshopify.com', //`${process.env.SHOPIFY_STORE}.myshopify.com`,
  storefrontAccessToken: '8d8be545cfd02316b7b9480d71eb14ed', //process.env.SHOPIFY_STOREFRONT_TOKEN,
})

const UpdateItems = ({state, setStorageState}) => {

  const [states, setstates] = useState(state)
  const [first, setFirst] = useState(null)
  const {completed, networkStatus} = useOrderRefresher()
  const [checkSet, setCheckSet] = useState()
  const [orderMeta, setOrderMeta] = useState(null)
  useEffect(() => {
    setOrderMeta(state.order.order_metadata)
    return () => {}
  }, [completed, networkStatus, state?.order?.order_metadata])
  const [replacementError, setReplacementError] = useState(null)

  const [status, setStatus] = useState('idle') // idle, pending, resolved, rejected
  const [itemsToUpdate, setItemsToUpdate] = useState([])
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [trueState, setTureState] = useState()
  const [mutate] = useMutation(UPDATE_ORDER_ITEMS, {
    onCompleted: data => {
      if (data.updateOrderItems.status === 'success') {
        setStatus('resolved')
        setItemsToUpdate([])
        data.updateOrderItems.order &&
          setStorageState({
            ...state,
            order: data.updateOrderItems.order,
            timestamp: Date.now(),
          })
        data.updateOrderItems.order &&
          setstates({
            ...state,
            order: data.updateOrderItems.order,
            timestamp: Date.now(),
          })
        // router.push(
        //   `/order/${state.orderNumber}/success?source=updated_items`,
        // )
      } else {
        setStatus('rejected')
      }
    },
  })

  useEffect(() => {
    setstates(state)
  }, [state])

  useEffect(() => {
    checkIsFormValid()
  }, [first, itemsToUpdate])

  const checkHasEdit = useCallback((itemId, details) => {
    setItemsToUpdate(prev => {
      // 1. Is the item already added in the array?
      const isItemInItems = prev.find(
        item => +item.id === +itemId,
      )
      if (isItemInItems) {
        return prev.map(item => +item.id === +itemId ? {...item, ...details} : item)
      }
      // return prev
      return [...prev, {id: +itemId, ...details}]
    })
  }, [])

  const {order} = state
  if (!state.order) return null
  const {order_items, folder_id} = order

  function checkIsFormValid() {
    if (!first) {
      setButtonDisabled(false)
      setReplacementError('Please select a scent.')
    } else setButtonDisabled(true)

    if (status === 'pending') setButtonDisabled(true)
    else setButtonDisabled(false)

    if (!itemsToUpdate.length) setButtonDisabled(true)
    else setButtonDisabled(false)

    if (itemsToUpdate.length > 0) {
      itemsToUpdate.forEach(item => {
        // console.log('Item: ', item)
        if (item?.crop?.startsWith('Other')) {
          if (item.customCrop == '') setButtonDisabled(true)
          else setButtonDisabled(false)
        }
      })
    }
  }

  const flaggedReason = order.order_metadata.find(
    v => v.key === 'Flagged Reason',
  )?.value

  const currentAction = actions.find(
    action => action.id === 'update-items',
  )

  if (
    !currentAction ||
    (!currentAction.folders.includes(folder_id) &&
      !currentAction.flaggedFolders.includes(folder_id) &&
      flaggedReason !== 'Design')
  ) {
    return <UnavailableAction />
  }

  return (
    <>
      <div className="item-box px-0">
        <div tw="w-full">
          {order_items.map(item => {
            return (
              <>
                <div className="">
                  <UpdateItem
                    setCheckSet={setCheckSet}
                    states={states}
                    mutate={mutate}
                    itemsToUpdate={itemsToUpdate}
                    key={item.id}
                    item={item}
                    updater={checkHasEdit}
                    orderId={order.id}
                    order={order}
                    replacementError={replacementError}
                    setFirst={setFirst}
                    remover={undefined}
                    refundState={undefined}
                    setTureState={setTureState}
                    trueState={undefined}
                  />
                </div>
              </>
            )
          })}
        </div>
      </div>
    </>
  )
}

UpdateItems.getLayout = getLayout

export default UpdateItems
UpdateItems.displayName = 'UpdateItems'

export const UpdateItem = ({
  trueState,
  setTureState,
  refundState,
  setCheckSet,
  states,
  mutate,
  itemsToUpdate,
  replacementError,
  setFirst,
  item,
  updater,
  orderId,
  order,
  remover,
  type = 'New',
  quantity = null,
  showQuantity = false,
  updateOrderItem = null
}) => {
  const {order_items, folder_id} = order
  const [isToggled, toggle] = useBoolean(false)
  const {image, scent, crop, flag} = useOrderItem(item)
  const [saveStatus, setSaveStatus] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [orderItems, setOrderItems] = useState()
  const [state, setState] = useState({
    crop,
    customCrop: '',
    scent,
    image,
    quantity,
    clearFlag: false,
  })
  const [testScent, setTestScent] = useState(state.scent)
  const [testCrop, setTestCrop] = useState(state.crop)
  const [modalClose, setmodalClose] = useState(false)  
  const [testQuantity, settestQuantity] = useState(
    state.quantity,
  )

  const didMountRef = useRef(false)

  useEffect(() => {
    if (!didMountRef.current) didMountRef.current = true
    else {
      state.quantity === 0 && remover
        ? remover(item.id)
        : updater(item.id, state)
    }
    return () => {}
  }, [state])

  useEffect(() => {
    if (!isToggled) return
    if (updater && !state.quantity)
      setState({...state, quantity: 0})
    if (item.variation_list.length > 0) {
      item.variation_list.forEach(item => {
        if (item.key == 'customCrop') {
          setState({...state, customCrop: item.value})
        }
      })
    }
  }, [isToggled])

  useEffect(() => {
    if(state.crop != 'Just The Head(s)' && state.crop != 'Entire Body(s)' && state.crop != 'Do Not Crop' && state.crop != 'Just The Car') {
      const other_crop = item?.metadata?.find(
        v => v.key === 'Crop'
      )?.value

      setState({
        ...state,
        customCrop: other_crop
      })
    }
  }, [])

  const handlePhotoChange = (url) => {
    setState({
      ...state,
      image: url,
      clearFlag: true,
    })

    // if(updateOrderItem != null) {
    //   updateOrderItem(item, 'image', url)
    // }
  }

  const handleCropChange = (crop) =>{
    if (!showQuantity) {
      setState({...state, crop})
    }

    setTestCrop(crop)

    // if(updateOrderItem != null) {
    //   updateOrderItem(item, 'Crop', crop)
    //   updateOrderItem(item, 'customCrop', '')
    // }
  }

  const handleScentChange = (scent)=> {
    if (!showQuantity) {
      setState({...state, scent})
    }

    setTestScent(scent)

    // if(updateOrderItem != null) {
    //   updateOrderItem(item, 'Scent', scent)
    // }
  }

  useEffect(() => {
    if (modalClose) {
      setTestScent(scent)
      setTestCrop(crop)
    }
  }, [modalOpen])

  const handleQuantityChange = (event)=> {
    settestQuantity(event.target.value)
  }

  const FlagofItem = item?.variation_list?.find(
    v => v.key === 'Flag',
  )?.value

  return (
    <div
      className={`
      ${!showQuantity && 'item-outer border-gray'}
      ${showQuantity && 'item-outer border-gray'}
      ${!showQuantity && FlagofItem && 'item-outer border-red'}
                      ${
                        state.quantity > 0 &&
                        showQuantity &&
                        saveStatus &&
                        'item-outer border-blue'
                      }
                      ${
                        state.quantity === 0 &&
                        showQuantity &&
                        // saveStatus &&
                        'item-outer border-gray'
                      }
                      `}
      key={item.id}
    >
      <div
        className="edit-items-box edit-items-box-inner"
        key={item.id}
        css={[
          tw`mt-2 -mx-2 p-2 flex flex-wrap relative`,
          isToggled && tw``,
        ]}
      >
        {/* <div className="banner-text">Action Required</div> */}
        <div
          className={`${
            refundState?.solution === 'free-replacement' &&
            'banner-text colorGray'
          }
        ${FlagofItem && !showQuantity && 'banner-text'}
        ${
          !saveStatus &&
          showQuantity &&
          state.quantity > 0 &&
          'banner-text colorGray'
        }
        ${
          showQuantity &&
          state.quantity > 0 &&
          saveStatus &&
          'banner-text colorBlue'
        }
        ${
          showQuantity &&
          state.quantity === 0 &&
          'banner-text colorGray '
        }
        `}
        >
          {FlagofItem && !showQuantity
            ? FlagofItem
            : !saveStatus && showQuantity && state.quantity > 0
            ? `${0}/${item.quantity} selected`
            : showQuantity && state.quantity > 0
            ? `${state.quantity}/${item.quantity} selected`
            : showQuantity && state.quantity === 0
            ? `${state.quantity}/${item.quantity} selected`
            : ''}
        </div>

        <OrderItem
          states={states}
          // order_items={order_items}
          item={item}
          hasPrice={false}
          clickHandler={toggle}
          setOrderItems={setOrderItems}
          shQuantity={undefined}
        >
          <span onClick={toggle}>
            <div className="edit-btn">
              <button
                className="btn brand-btn edit-btn"
                onClick={() => {
                  setModalOpen(!modalOpen)
                  // setSaveStatus(false)
                  settestQuantity(state.quantity)
                }}
              >
                {(refundState?.solution === 'free-replacement' &&
                  saveStatus &&
                  state.quantity > 0) ||
                (showQuantity &&
                  saveStatus &&
                  state.quantity > 0)
                  ? 'Edit'
                  : showQuantity
                  ? 'Select'
                  : 'Edit'}
              </button>
            </div>
          </span>
        </OrderItem>

        <Modal
          toggle={() => setModalOpen(!modalOpen)}
          isOpen={modalOpen}
          className="popup-dialog edit-item-popup"
          backdrop="static"
        >
          {' '}
          <div className="p-2">
            <div className="top-header-item">
              <div className="d-flex align-items-center">
                <span className="d-flex mr-4 cart-icon">
                  {!showQuantity ? (
                    <FontAwesomeIcon icon={faList} />
                  ) : (
                    <FontAwesomeIcon icon={faRightLeft} />
                  )}
                </span>
                <span className="heading">
                  {!showQuantity ? 'Edit Items' : 'Replacement'}
                </span>
              </div>
              <span
                aria-hidden={true}
                onClick={() => {
                  setModalOpen(!modalOpen)
                  setmodalClose(true)
                  // setState({
                  //   ...state,
                  //   quantity: 0,
                  //   scent: scent,
                  //   crop: crop,
                  // })
                }}
                className="cursor-pointer close-btn"
              >
                <span className="close-icon d-flex cart-icon">
                  <FontAwesomeIcon icon={faXmark} />
                </span>
              </span>
            </div>
          </div>
          <div className="modal-content-box ">
            <div className="modal-body pt-0">
              <div
                className="crop-box"
                css={[
                  tw`p-2 sm:grid sm:grid-cols-2 sm:gap-x-4 md:gap-x-16 gap-y-1 w-full pb-8`,
                ]}
              >
                {!flag || flag !== 'Delivery' ? (
                  <UploadItemPhoto
                    state={state}
                    orderId={orderId}
                    itemId={item.id}
                    updatePhoto={handlePhotoChange}
                    type={type}
                    defaultImage={image}
                  />
                ) : (
                  <span>&nbsp;</span>
                )}
                <div tw="sm:mt-0">
                  {showQuantity ? (
                    <div tw="mb-4">
                      <h3 tw="text-lg text-brand">
                        How many need to be replaced?
                      </h3>
                      <div className="select-box">
                        <span className="select-icon">
                          <i className="fa fa-caret-down"></i>
                        </span>
                        <select
                          onChange={handleQuantityChange}
                          required={true}
                          value={testQuantity}
                          tw="h-full w-full p-2 border border-gray-400 bg-white text-gray-500 focus:ring-brand rounded-md"
                        >
                          <option
                            key={0}
                            value={0}
                            css={[tw`block w-full hover:bg-gray-100 rounded-md`]}
                          >0</option>
                          {Array.from(Array(item.quantity? item.quantity : 0), (e, i) => {
                            return <option
                              key={i+1}
                              value={i+1}
                            >{i+1}</option>
                          })}
                        </select>
                      </div>
                    </div>
                  ) : null}
                  <ScentAction
                    replacementError={replacementError}
                    setFirst={setFirst}
                    currentScent={testScent}
                    setNewScent={handleScentChange}
                    type={type}
                  />
                  <CropAction
                    currentCrop={testCrop}
                    setNewCrop={handleCropChange}
                    type={type}
                  />
                  {testCrop != 'Just The Head(s)' &&
                  testCrop != 'Entire Body(s)' &&
                  testCrop != 'Do Not Crop' &&
                  testCrop != 'Just The Car' ? (
                    <div tw="mt-4">
                      <h3 tw="text-lg text-brand">Other</h3>
                      <input
                        type="text"
                        required={true}
                        name="customCrop"
                        value={state.customCrop}
                        onChange={e => {
                          setState({
                            ...state,
                            customCrop: e.currentTarget.value,
                            crop: e.currentTarget.value,
                          })
                          if (showQuantity) {
                            setTestCrop(e.currentTarget.value)
                          }

                          // if(updateOrderItem != null) {
                          //   updateOrderItem(item, 'Crop', e.currentTarget.value)
                          //   updateOrderItem(item, 'customCrop', e.currentTarget.value)
                          // }
                        }}
                        placeholder="Please specify"
                        tw="h-full w-full p-2 border border-gray-400 bg-white text-gray-500 focus:ring-brand rounded-md"
                      />
                      {state?.customCrop == '' &&
                        testCrop?.replace('Other', '') == '' && (
                          <span style={{color: 'red'}}>
                            Please enter some value.
                          </span>
                        )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="text-center">
                <button
                  disabled={
                    (!showQuantity && state?.crop === '' &&
                      state?.customCrop === '') ||
                    (testCrop === '' && state?.customCrop === '')
                  }
                  onClick={() => {
                    toastr.options = {
                      positionClass : 'toast-top-right',
                      hideDuration: 300,
                      timeOut: 3000
                    }

                    toastr.success(`Saved`)

                    if (!showQuantity) {
                      mutate({
                        variables: {
                          orderId: order.id,
                          items: itemsToUpdate,
                        },
                      })
                    }

                    if(updateOrderItem != null) {
                      updateOrderItem(item, {
                        image: state.image,
                        crop: testCrop,
                        scent: testScent,
                        customCrop: state.customCrop
                      })
                    }

                    if (showQuantity) {
                      const quantity =
                        testQuantity <= item.quantity
                          ? Number(testQuantity)
                          : state.quantity
                      setState({
                        ...state,
                        quantity,
                        scent: testScent,
                        crop: testCrop,
                      })
                    }

                    setModalOpen(!modalOpen)
                    setTureState(state.quantity)
                    setSaveStatus(true)
                    setCheckSet(true)
                  }}
                  className="btn brand-btn popup-btn"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  )
}

export const ADD_PHOTO = gql`
  mutation UploadPhoto(
    $file: Upload!
    $orderId: String!
    $itemId: Int!
  ) {
    uploadPhoto(
      file: $file
      orderId: $orderId
      itemId: $itemId
    ) {
      status
      message
      execution_time
      url
    }
  }
`

export const UploadItemPhoto = ({
  state,
  orderId,
  itemId,
  updatePhoto,
  type,
  defaultImage = null,
}) => {
  const [photo, setPhoto] = useState(defaultImage)
  const [isLoading, setIsLoading] = useState(false)
  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader()
      uploadPhoto(file)
      reader.onload = () => {
        setIsLoading(true)
      }
      reader.readAsDataURL(file)
    })
  }, [state])

  const uploadPhoto = async (file) => {
    AWS.config.update({
      accessKeyId: AWS_ACCESS_KEYID,
      secretAccessKey: AWS_ACCESS_KEY_SECRET
    })
    
    const myBucket = new AWS.S3({
        params: { Bucket: AWS_S3_BUCKET},
        region: AWS_S3_REGION,
    })

    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: AWS_S3_BUCKET,
      Key: `${orderId}-${itemId}-${file.name}`
    };

    try {
      var result = await myBucket.upload(params).promise()
      updatePhoto(result.Location)
      setPhoto(result.Location)
      setIsLoading(false)
    } catch(error) {
      console.log(error)
    }
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
  } = useDropzone({onDrop})

  const [mutate, {loading, error, data}] = useMutation(
    ADD_PHOTO,
    {
      onCompleted: data => {
        console.log(data, error)
        console.log(data)
        if (data.uploadPhoto.status === 'success') {
          updatePhoto(data.uploadPhoto.url)
          setPhoto(data.uploadPhoto.url)
          setIsLoading(false)
        } else {
          console.log('On Complete Error: ', data)
        }
      },
      onError: error => {
        console.log('Upload Photo Error: ', error)
      },
    },
  )

  return (
    <div
      tw="relative sm:h-full sm:min-h-md "
      className="img-crop-outer"
    >
      {type ? (
        <h3 tw="text-lg text-brand mb-2">
          {/* {type} Photo */}
          Photo
        </h3>
      ) : null}
      <div
        className="img-crop-box"
        {...getRootProps()}
        css={[
          tw`h-full relative border-dashed border-gray-300 flex items-center justify-center p-6 bg-white text-sm text-brand text-center`,
          isDragActive && tw`border-brand`,
          // isLoading && tw`border-brand bg-gray-100`,
        ]}
      >
        {error}
        {isLoading && !error && (
          <svg
            tw="animate-spin mx-auto h-24 w-24 text-brand"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              tw="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              tw="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        <input {...getInputProps()} />
        {photo && (
          <img
            src={photo}
            alt="New picture"
            tw="max-w-full max-h-full object-contain"
            width="400"
            height="260"
            // objectFit="contain"
          />
        )}
        {!photo && isDragActive && (
          <p>Drop the files here ...</p>
        )}
        {!photo && !isDragActive && !isLoading && (
          <div>
            <CloudUploadIcon
              css={[tw`h-12 w-12 mx-auto text-brand`]}
            />
            <p tw="mt-2">
              <span className="semi-bold text-size">
                Upload Photo
              </span>{' '}
              <br />
              <span className="text-gray">
                Drag &amp; Drop or <u>Browse</u>
              </span>
            </p>
          </div>
        )}
      </div>
      {photo && (
        <button type="reset" onClick={e => setPhoto(null)}>
          <XIcon tw="absolute top-2 right-2 w-5 h-5" />
        </button>
      )}
    </div>
  )
}
